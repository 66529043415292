import { uk } from '@/features/iti/i18n.ts';
import type { i18n } from 'i18next';
// @ts-ignore
import en from 'intl-tel-input/i18n/en';

export const initOptions = (i18n: i18n) => ({
    initialCountry: 'ua',
    onlyCountries: ['ua', 'de', 'sk'],
    nationalMode: false,
    i18n: i18n.language === 'uk' ? uk : en,
});
