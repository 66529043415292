import { Breadcrumb, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '@/features/router';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { CountriesTable } from '@/features/countries';

export const Countries = () => {
    const { t } = useTranslation('countries');
    const navigate = useNavigate();

    const breadcrumbItems = [
        {
            title: <Link to={ROUTES.DASHBOARD}>{t('breadcrumbs:home')}</Link>,
        },
        {
            title: <Link to={ROUTES.REGIONS}>{t('breadcrumbs:regions')}</Link>,
        },
        {
            title: t('breadcrumbs:countries'),
        },
    ];

    return (
        <>
            <div className="flex flex-col md:flex-row justify-between mb-4 gap-y-2.5">
                <Breadcrumb items={breadcrumbItems} />

                <div className='flex gap-x-2.5'>
                    <Button type="primary" className='w-full' icon={<PlusOutlined />} onClick={() => navigate(ROUTES.COUNTRY_ADD)}>
                        {t('countries:add_country')}
                    </Button>
                    <Button type="primary" className='w-full' icon={<PlusOutlined />} onClick={() => navigate(ROUTES.REGION_ADD)}>
                        {t('countries:add_region')}
                    </Button>
                </div>
            </div>
            <CountriesTable />
        </>
    );
};
