import { UserAddForm } from '@/features/users';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { ROUTES } from '@/features/router';
import { useTranslation } from 'react-i18next';

export const AddUser = () => {
	const { t } = useTranslation('breadcrumbs');

	const breadcrumbItems = [
		{
			title: <Link to={ROUTES.DASHBOARD}>{t('breadcrumbs:home')}</Link>
		},
		{
			title: <Link to={ROUTES.USERS}>{t('breadcrumbs:users')}</Link>
		},
		{
			title: t('breadcrumbs:add_user')
		}
	];

	return (
		<>
			<Breadcrumb className="mb-4" items={breadcrumbItems} />
			<UserAddForm />
		</>
	);
};
