import React from 'react';
import { useAuthContext } from '@/features/auth';
import { useTranslation } from 'react-i18next';
import { Flex } from 'antd';

export const UserProfileInfo: React.FC = () => {
    const { currentUser } = useAuthContext();
    const { t } = useTranslation('common');

    return (
        <Flex vertical align="center" gap={8} className='mr-2 pointer-events-none'>
            <div className='text-sm leading-[48px]'>
                {t('users:counterparty')}: {currentUser?.counterparty}
            </div>
            <div className='text-sm'>
                {t('users:role')}: {t(currentUser?.role!)}
            </div>
        </Flex>
    );
};
