import { useAxios } from '@/features/axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { API_ROUTES } from '@/api/routes.ts';
import { DeviceEntityClass, NotificationInfoRequest, NotificationInfoResponse } from '@/types.ts';

export const useNotificationInfo = (id: number, entity: DeviceEntityClass) => {
    const axios = useAxios();

    return useQuery({
        queryKey: [API_ROUTES.NOTIFICATION_INFO.KEY, id, entity],

        queryFn: async () => {
            const params = {
                entity_class: entity,
            };

            return (await axios.get<NotificationInfoResponse>(`${API_ROUTES.NOTIFICATION_INFO.URL}/${id}`, { params })).data;
        },
    });
};

export const useUpdateNotificationInfo = (id: number) => {
    const axios = useAxios();
    return useMutation({
        mutationFn: async (info: NotificationInfoRequest) => {
            return (await axios.put<NotificationInfoResponse>(`${API_ROUTES.NOTIFICATION_INFO.URL}/${id}`, info)).data;
        },
    });
};
