import React from 'react';
import { Select } from 'antd';
import { useSetLang } from './../hooks';
import { useProfile } from '@/features/profile';
import { useAuthContext } from '@/features/auth';
import { useUserLocaleContext } from './../context';

type LangSwitcherProps = {};

export const LangSwitcher: React.FC<LangSwitcherProps> = () => {
    const { mutateAsync } = useSetLang();
    const { refetch: getProfile } = useProfile(false);
    const { isAuth, setActiveUser } = useAuthContext();
    const { userLocale, setUserLocale } = useUserLocaleContext();

    const handleLangChange = async (lang: string) => {
        try {
            if (isAuth) {
                await mutateAsync(lang);

                const response = await getProfile();
                if (response?.data?.data) {
                    setActiveUser(response.data.data);
                }
            } else {
                setUserLocale(lang);
            }
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <Select
            value={userLocale}
            style={{ width: 'max-content' }}
            onChange={handleLangChange}
            options={[
                { value: 'uk', label: 'Українська' },
                { value: 'en', label: 'English' },
            ]}
            variant="borderless"
        />
    );
};
