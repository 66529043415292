import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '@/features/auth';
type PrivateRouteProps = React.PropsWithChildren;

export const PrivateRoute = ({ children }: PrivateRouteProps) => {
    const { isAuth } = useAuthContext();

    if (!isAuth) {
        return <Navigate to="/auth/login" />;
    }

    return children;
};
