import { useAxios } from '@/features/axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API_ROUTES } from '@/api/routes.ts';
import {
    CountDataResponse,
    DeviceRequest,
    DeviceResponse,
    DevicesTableCollection,
    DevicesTableFilter,
    DevicesTableSortBy,
    DeviceTestRequest,
    MikrotikSettingsResponse,
} from '@/types.ts';

export const useDevices = (page: number, perPage?: number, filterBy?: DevicesTableFilter, sortBy?: DevicesTableSortBy) => {
    const axios = useAxios();

    return useQuery({
        queryKey: [API_ROUTES.DEVICES.KEY, page, perPage, filterBy, sortBy],

        queryFn: async () => {
            let params = {
                filterBy: {} as DevicesTableFilter,
                sortBy: {} as DevicesTableSortBy,
                page: page,
                per_page: perPage,
            };

            if (filterBy) {
                if (filterBy.country_id) {
                    params.filterBy.country_id = filterBy.country_id;
                }
                if (typeof filterBy.state !== 'undefined') {
                    params.filterBy.state = filterBy.state;
                }
                if (filterBy.device_model_id) {
                    params.filterBy.device_model_id = filterBy.device_model_id;
                }
                if (filterBy.deleted?.length) {
                    params.filterBy.deleted = filterBy.deleted;
                }
            }

            if (sortBy) {
                if (sortBy.serial_number) {
                    params.sortBy.serial_number = sortBy.serial_number;
                }

                if (sortBy.country) {
                    params.sortBy.country = sortBy.country;
                }

                if (sortBy.region) {
                    params.sortBy.region = sortBy.region;
                }
                if (sortBy.address) {
                    params.sortBy.address = sortBy.address;
                }

                if (sortBy.diller) {
                    params.sortBy.diller = sortBy.diller;
                }
            }

            return (
                await axios.get<DevicesTableCollection>(`${API_ROUTES.DEVICES.URL}`, {
                    params,
                })
            ).data;
        },
    });
};

export const useAddDevice = () => {
    const axios = useAxios();

    return useMutation({
        mutationFn: async (device: DeviceRequest) => {
            return (await axios.post<DeviceResponse>(API_ROUTES.DEVICES.URL, device)).data;
        },
    });
};

export const useGetDevice = (id: number) => {
    const axios = useAxios();
    return useQuery({
        queryKey: [API_ROUTES.DEVICES.KEY, id],
        queryFn: async () => {
            return (await axios.get<DeviceResponse>(`${API_ROUTES.DEVICES.URL}/${id}`)).data;
        },
    });
};

export const useUpdateDevice = (id: number) => {
    const axios = useAxios();
    return useMutation({
        mutationFn: async (device: DeviceRequest) => {
            return (await axios.put<DeviceResponse>(`${API_ROUTES.DEVICES.URL}/${id}`, device)).data;
        },
    });
};

export const useDeleteDevice = () => {
    const axios = useAxios();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (id: number) => {
            return (await axios.delete<DeviceResponse>(`${API_ROUTES.DEVICES.URL}/${id}`)).data;
        },
        onSuccess: async () => {
            await client.invalidateQueries({ queryKey: [API_ROUTES.DEVICES.KEY] });
        },
    });
};

export const useCountData = () => {
    const axios = useAxios();

    return useQuery({
        queryKey: [API_ROUTES.COUNT_DATA.KEY],

        queryFn: async () => {
            return (await axios.get<CountDataResponse>(`${API_ROUTES.COUNT_DATA.URL}`)).data;
        },
    });
};

export const useTestDevice = () => {
    const axios = useAxios();

    return useMutation({
        mutationFn: async (device: DeviceTestRequest) => {
            return (await axios.post(API_ROUTES.DEVICE_TEST.URL, device)).data;
        },
    });
};

export const useMikrotikSettings = (id: number) => {
    const axios = useAxios();
    return useQuery({
        queryKey: [API_ROUTES.MIKROTIK_SETTINGS.KEY],

        queryFn: async () => {
            return (await axios.get<MikrotikSettingsResponse>(`${API_ROUTES.MIKROTIK_SETTINGS.URL}/${id}`)).data;
        },
    });
};
