import {createContext, useContext} from 'react';
import {MessageInstance} from 'antd/es/message/interface';

interface MessageApiContextProps {
    messageApi: MessageInstance,
}

export const MessageApiContext = createContext<MessageApiContextProps>({
	messageApi: {} as MessageInstance
});

export const useMessageApiContext = () => useContext(MessageApiContext);

