import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { ROUTES } from '@/features/router';
import { useTranslation } from 'react-i18next';
import { DeviceModelsAddForm } from '@/features/device-models';

export const AddDeviceModel = () => {
    const { t } = useTranslation('breadcrumbs');

    const breadcrumbItems = [
        {
            title: <Link to={ROUTES.DASHBOARD}>{t('breadcrumbs:home')}</Link>,
        },
        {
            title: <Link to={ROUTES.DEVICE_MODELS}>{t('breadcrumbs:device_models')}</Link>,
        },
        {
            title: t('breadcrumbs:add_device_model'),
        },
    ];

    return (
        <>
            <Breadcrumb className="mb-4" items={breadcrumbItems} />
            <DeviceModelsAddForm />
        </>
    );
};
