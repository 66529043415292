import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import { ROUTES } from '@/features/router';
import { useTranslation } from 'react-i18next';
import { RegionAddForm } from '@/features/regions';

export const AddRegion = () => {
    const { t } = useTranslation('breadcrumbs');

    const breadcrumbItems = [
        {
            title: <Link to={ROUTES.DASHBOARD}>{t('breadcrumbs:home')}</Link>,
        },
        {
            title: <Link to={ROUTES.REGIONS}>{t('breadcrumbs:regions')}</Link>,
        },
        {
            title: t('breadcrumbs:add_region'),
        },
    ];

    return (
        <>
            <Breadcrumb className="mb-4" items={breadcrumbItems} />
            <RegionAddForm />
        </>
    );
};
