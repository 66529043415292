import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Breadcrumb, Button, Flex } from 'antd';
import { ROUTES } from '@/features/router';
import { OsmoDevicesTable } from '@/features/osmo_devices/component/OsmoDevicesTable.tsx';
import { PlusOutlined } from '@ant-design/icons';
import { useRBAC } from '@/features/auth';

export const OsmoDevices = () => {
    const { isAdmin } = useRBAC();
    const { t } = useTranslation('breadcrumbs');
    const navigate = useNavigate();

    const breadcrumbItems = [
        {
            title: t('home'),
        },
        {
            title: <Link to={`${ROUTES.OSMO_DEVICES}`}>{t('osmo_devices')}</Link>,
        },
    ];

    return (
        <Flex vertical gap={15}>
            <Flex justify="space-between" className='flex-col gap-y-4 md:flex-row md:items-center'>
                <Breadcrumb items={breadcrumbItems} />

                {isAdmin() && (
                    <Button type="primary" className='w-max' icon={<PlusOutlined />} onClick={() => navigate(ROUTES.OSMO_DEVICES_ADD)}>
                        {t('add_device')}
                    </Button>
                )}
            </Flex>
            <OsmoDevicesTable />
        </Flex>
    );
};
