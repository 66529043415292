import logo from '@/assets/logo.svg';
import React from 'react';
import { LangSwitcher } from '@/features/i18n/components';
import { CustomMenu } from '@/layouts/components/CustomMenu.tsx';
import { ProfileDropdown } from '@/features/profile';
import { Layout } from 'antd';
import { useAdaptiveContext } from '@/features/adaptive/context/Context.ts';
import { MobileHeader } from '@/features/adaptive/components/MobileHeader.tsx';

const { Header } = Layout;

type CustomHeaderProps = {};

export const CustomHeader: React.FC<CustomHeaderProps> = () => {
    const { isTabletOrMobile } = useAdaptiveContext();

    return (
        <>
            {isTabletOrMobile && <MobileHeader /> || <Header className={'flex justify-between items-center bg-white h-[48px] leading-[48px] px-4'}>
                <img src={logo} className="max-w-[147px] logo cursor-pointer" alt="Akvantis" />
                <CustomMenu />
                <div className={'flex items-center h-[48px]'}>
                    <LangSwitcher />
                    <ProfileDropdown />
                </div>
            </Header>}
        </>
    );
};
