import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Button, Col, Row, Spin } from 'antd';
import { ROUTES } from '@/features/router';
import { ClientStatusBadge } from '@/features/client-mobile/components/ClientStatusBadge.tsx';
import { WarningOutlined } from '@ant-design/icons';
import { useGetDeviceData } from '@/features/client-mobile/hooks/api.ts';
import { OsmoDeviceAnalytics, OsmoDeviceData } from '@/types.ts';
import car_icon from '@/assets/car.svg';

export const osmoDeviceData: (keyof OsmoDeviceAnalytics)[] = [
    'TDS',
    'FLOW_RATE',
    'TOTAL_L',
    'INLET_PRESSURE',
    'PRESSURE_AFTER_PUMP',
    'PRESSURE_AFTER_MEMBRANE',
];

export const ClientOsmoDevice: React.FC = () => {
    const { id } = useParams();
    const { t } = useTranslation('breadcrumbs');
    const navigate = useNavigate();

    const { data, isLoading } = useGetDeviceData<OsmoDeviceData>(parseInt(id!), 'arduino_device');

    const breadcrumbItems = [
        {
            title: <Link to={ROUTES.DASHBOARD}>{t('home')}</Link>,
        },
        {
            title: `${t('osmo_devices')}, ${data?.data?.address ?? ''}`,
        },
    ];

    return (
        <>
            <Spin spinning={isLoading} fullscreen />
            <Row gutter={[16, 16]} className="mb-[29px]">
                <Col span={24}>
                    <Breadcrumb items={breadcrumbItems} />
                </Col>
                <Col span={24}>
                    <ClientStatusBadge state={data?.data?.state} status={data?.data?.status} />
                </Col>
            </Row>
            <div className="flex flex-col gap-y-5 items-center">
                <span className="text-[20px] leading-[24px] font-medium uppercase">{t('osmo_devices')}</span>
                <Row gutter={[8, 8]}>
                    {osmoDeviceData.map((item) => (
                        <Col key={item} flex="1 0 33.33%">
                            <div className="flex bg-white rounded-[8px] flex-col px-[12px] py-[14px] gap-y-[5px] h-full justify-between">
                                <span className="text-[12px] leading-[14px] font-normal">{t(`osmo_devices:${item}`)}</span>
                                <span className="text-[20px] leading-[30px] font-medium">{data?.data?.data[item] ?? 0}</span>
                            </div>
                        </Col>
                    ))}
                    <Col flex="1 0 100%">
                        <div className="flex bg-white rounded-[8px] flex-row px-[12px] py-[14px] h-full items-center justify-between">
                            <div className="flex flex-row gap-x-[10px] items-center">
                                <img src={car_icon} className="w-[44px] h-[26px]" />
                                <span className="text-[16px] leading-[24px] font-medium">{t('osmo_devices:UP_TO_THE_SERVICE')}</span>
                            </div>
                            <span className="text-[20px] leading-[30px] font-medium">{data?.data?.data?.UP_TO_THE_SERVICE ?? 0}</span>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="flex justify-end fixed bottom-[19px] right-[10px]">
                <Button
                    type="primary"
                    icon={<WarningOutlined />}
                    className="w-max"
                    onClick={() => {
                        navigate(`${ROUTES.OSMO_DEVICES_ACCIDENTS}/${data?.data?.id}`, { state: { prevLocation: location.pathname } });
                    }}
                >
                    {t('accidents')}
                </Button>
            </div>
        </>
    );
};
