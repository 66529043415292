import { useAxios } from '@/features/axios';
import { useQuery } from '@tanstack/react-query';
import { API_ROUTES } from '@/api/routes.ts';
import { UserResponse } from '@/types.ts';

export const useProfile = (enabled?: boolean) => {
    const axios = useAxios();

    return useQuery({
        queryKey: [API_ROUTES.PROFILE.KEY],
        queryFn: async () => {
            return (await axios.get<UserResponse>(API_ROUTES.PROFILE.URL)).data;
        },
        enabled: enabled,
    });
};
