import { DeviceAccidentFilter } from '@/types';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, DatePicker, Form, Select } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import React from 'react';
import '@/styles/rangepicker.css'

type AccidentsItems = {
    [key: string]: string;
};

type AccidentsFilterProps = {
    handleFilter: any;
    refresh: () => void;
    accidents: AccidentsItems;
    loading: boolean;
};

export const AccidentsFilter: React.FC<AccidentsFilterProps> = ({ handleFilter, refresh, accidents, loading }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation(['devices', 'accidents_text']);
    const { RangePicker } = DatePicker;

    const handleSelect = (value: string) => {
        handleFilter((prev: DeviceAccidentFilter) => ({ ...prev, event_type: value }));
    };

    const handleRangeChange = (_dates: any, dateStrings: string[]) => {
        const [start, end] = dateStrings;
        handleFilter((prev: DeviceAccidentFilter) => ({
            ...prev,
            date_from: start,
            date_to: moment(end).add(1, 'days').format('YYYY-MM-DD'),
        }));
    };

    const handleCancel = async () => {
        await handleFilter(undefined);
        form.setFieldsValue({ accident: undefined, accidentDate: [] });

        refresh();
    };

    return (
        <Card className="mb-4">
            <Form
                className="grid grid-flow-row md:grid-flow-col gap-4"
                form={form}
                labelCol={{ xs: { span: 24 }, md: { flex: '1 0 auto' } }}
                wrapperCol={{ xs: { span: 24 }, md: { flex: '1 0 auto' } }}
            >
                <Form.Item name="accident" label={t('accidents:accident_type')} className="mb-0">
                    <Select placeholder={t('accidents:input_accident')} onChange={handleSelect}>
                        {Object.entries(accidents ?? {}).map(([key, value]) => (
                            <Select.Option key={key} value={key}>
                                {value}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item name="accidentDate" label={t('accidents:date')} className="mb-0">
                    <RangePicker className="w-full" onChange={handleRangeChange} />
                </Form.Item>

                <div className="flex gap-x-2.5 ml-0 md:ml-auto">
                    <Button icon={<CloseOutlined />} className="w-full md:w-max" onClick={handleCancel}>
                        {t('accidents:cancel')}
                    </Button>
                    <Button type="primary" icon={<SearchOutlined />} className="w-full md:w-max" loading={loading} onClick={refresh}>
                        {t('accidents:search')}
                    </Button>
                </div>
            </Form>
        </Card>
    );
};
