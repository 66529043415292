import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import axios from 'axios';
import { ERRORS } from '@/utils/constants.ts';
import { useMessageApiContext } from '@/features/message-api/context';

export interface ConfirmDeleteModalProps {
    title: string;
    mutateAsync: (...args: any[]) => any;
    id: number;
    opened: boolean;
    setOpened: any;
}

export const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({ title, mutateAsync, id, opened, setOpened }) => {
    const [modal, contextHolder] = Modal.useModal();
    const { t } = useTranslation();
    const { messageApi } = useMessageApiContext();

    const deleteDevice = async () => {
        try {
            await mutateAsync(id);
            messageApi.open({
                type: 'success',
                content: t('common:message_success'),
            });
        } catch (e) {
            if (axios.isAxiosError(e)) {
                let message = t('common:message_error');
                if (e.response?.data.status === ERRORS.CONSTRAINT_ERROR) {
                    message = t('common:message_constraint_error');
                }

                messageApi.open({
                    type: 'error',
                    content: message,
                });
            }
        }
    };

    useEffect(() => {
        if (!opened) return;

        modal.confirm({
            title: title,
            icon: <ExclamationCircleFilled />,
            okText: t('common:delete_ok'),
            okType: 'danger',
            cancelText: t('common:delete_cancel'),
            onOk: async () => {
                await deleteDevice();
                setOpened(false);
            },
            onCancel: () => {
                setOpened(false);
            },
        });
    }, [opened]);

    return <>{contextHolder}</>;
};
