import React, { useState } from 'react';
import { Flex, Layout } from 'antd';

const { Header } = Layout;

import burgerIcon from '@/assets/burger.svg';
import logoMini from '@/assets/logo_mini.svg';
import { LangSwitcher } from '@/features/i18n/components';
import { ProfileDropdown } from '@/features/profile';
import { MobileMenuDrawer } from '@/features/adaptive/components/MobileMenuDrawer.tsx';
import { useRBAC } from '@/features/auth';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/features/router';

export const MobileHeader: React.FC = () => {
    const [menuOpened, setMenuOpened] = useState<boolean>(false);
    const { isClient } = useRBAC();
    const navigate = useNavigate();

    return (
        <>
            <Header className="grid grid-cols-2 h-[50px] bg-white !px-[15px]">
                <Flex align="center" justify="start" gap={15}>
                    {!isClient() && <img src={burgerIcon} className="w-[18px] h-[18px] cursor-pointer" onClick={() => setMenuOpened(true)} />}
                    <img src={logoMini} className="w-[28px] h-[28px]" onClick={() => {
                        navigate(ROUTES.DASHBOARD);
                    }} />
                </Flex>
                <Flex align="center" justify="end" className="h-[48px]">
                    <LangSwitcher />
                    <ProfileDropdown />
                </Flex>
            </Header>
            {!isClient() && <MobileMenuDrawer close={() => setMenuOpened(false)} show={menuOpened} />}
        </>
    );
};
