import { Breadcrumb, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '@/features/router';
import { useTranslation } from 'react-i18next';
import { CountryEditForm } from '@/features/countries';
import { UnorderedListOutlined } from '@ant-design/icons';

export const EditCountry = () => {
    const { t } = useTranslation('breadcrumbs');
    const navigate = useNavigate();

    const breadcrumbItems = [
        {
            title: <Link to={ROUTES.DASHBOARD}>{t('breadcrumbs:home')}</Link>,
        },
        {
            title: <Link to={ROUTES.REGIONS}>{t('breadcrumbs:regions')}</Link>,
        },
        {
            title: t('breadcrumbs:edit_country'),
        },
    ];

    return (
        <>
            <div className="flex flex-col md:flex-row justify-between mb-4 gap-y-2.5">
                <Breadcrumb items={breadcrumbItems} />

                <Button className='max-w-[169px]' icon={<UnorderedListOutlined />} onClick={() => navigate(ROUTES.COUNTRIES)}>
                    {t('countries:countries')}
                </Button>
            </div>
            <CountryEditForm />
        </>
    );
};
