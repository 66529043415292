import {FormInstance} from 'antd';
import {AxiosError} from 'axios';

type ResponseValidationError = {
  message: string;
  errors: never;
};
export const isValidationError = (error: unknown): error is ResponseValidationError => {
	return (
		typeof error === 'object' &&
    error != null &&
    'message' in error &&
    'errors' in error
	);
};

export const setFormServerErrors = (form: FormInstance, errors: AxiosError) => {
	for (const [key, value] of Object.entries(errors)) {
		form.setFields([
			{
				name: key,
				errors: [value as string]
			}
		]);
	}
};
