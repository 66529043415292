import { Button, Col, Form, Input, Modal, Row, Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAddUser } from '@/features/users';
import { useMessageApiContext } from '@/features/message-api/context';
import axios from 'axios';
import { generatePassword, setFormServerErrors } from '@/utils';
import { UserRequest, UserResponse } from '@/types.ts';
import { useRegions } from '@/features/regions';
import { ControlOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { ROLES } from '@/features/auth/constants.ts';
import { useCountries } from '@/features/countries';
import IntlTelInput from 'intl-tel-input/reactWithUtils';
import 'intl-tel-input/build/css/intlTelInput.css';
import { initOptions } from '@/features/iti/config.ts';

type UserAddModalProps = {
    isOpen: boolean;
    handleClose: () => void;
    handleSubmit: (user?: UserResponse) => void;
};

export const UserAddModal: React.FC<UserAddModalProps> = ({ isOpen = false, handleClose, handleSubmit }) => {
    const [form] = Form.useForm();
    const { i18n } = useTranslation();
    const countryValue = Form.useWatch('country_id', form);
    const { mutateAsync, isPending } = useAddUser();
    const { data: countries } = useCountries(1, 100);
    const { data: regions } = useRegions(1, 100, { country_id: countryValue });
    const { t } = useTranslation('users');
    const { messageApi } = useMessageApiContext();
    const [phone, setPhone] = useState<string | null>(null);
    const [isValidPhone, setIsValidPhone] = useState<boolean | null>(null);

    const onSubmit = async (values: UserRequest) => {
        try {
            let params: UserRequest = {
                counterparty: values.counterparty,
                role: ROLES.USER,
                phone: values.phone,
                email: values.email,
                password: values.password,
                country_id: values.country_id,
                region_id: values.region_id,
                address: values.address,
            };

            const response = await mutateAsync(params);
            handleSubmit(response);
            handleClose();
            messageApi.open({
                type: 'success',
                content: t('common:message_success'),
            });
        } catch (e) {
            messageApi.open({
                type: 'error',
                content: t('common:message_error'),
            });
            if (axios.isAxiosError(e)) {
                const errors = e.response!.data.errors;
                setFormServerErrors(form, errors);
            }
        }
    };

    useEffect(() => {
        form.setFieldValue('phone', phone);
    }, [phone, form]);

    const handleGeneratePassword = () => {
        form.setFieldValue('password', generatePassword());
    };

    const onCountryChange = () => {
        form.setFieldValue('region_id', null);
    };

    const onCloseModal = () => {
        handleClose();
    };

    return (
        <Modal open={isOpen} title={t('add_user')} onCancel={onCloseModal} destroyOnClose width={840} footer={null}>
            <Form form={form} name="form_add_user" layout="vertical" onFinish={onSubmit} requiredMark={false}>
                <Row gutter={16}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item<UserRequest>
                            name="counterparty"
                            label={t('counterparty')}
                            rules={[{ required: true, message: t('common:rule_required') }]}
                            className="mb-0"
                        >
                            <Input placeholder={t('counterparty')} />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item<UserRequest>
                            name="phone"
                            label={t('phone')}
                            rules={[
                                {
                                    required: true,
                                    message: t('common:rule_required'),
                                },
                                {
                                    message: t('common:rule_invalid_number'),
                                    validator: (_, value) => {
                                        if ((value && isValidPhone) || !value) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject();
                                        }
                                    },
                                },
                            ]}
                            className="mb-0"
                        >
                            <div key={i18n.language}>
                                <IntlTelInput onChangeNumber={setPhone} onChangeValidity={setIsValidPhone} initOptions={initOptions(i18n)} />
                            </div>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item<UserRequest>
                            name="email"
                            label={t('email')}
                            rules={[
                                { required: true, message: t('common:rule_required') },
                                { type: 'email', message: t('rules_email_valid') },
                            ]}
                            className="mb-0"
                        >
                            <Input placeholder={t('email')} />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 12 }}>
                        <Form.Item<UserRequest>
                            name="password"
                            label={t('password')}
                            rules={[{ required: true, message: t('common:rule_required') }]}
                            className="mb-0"
                        >
                            <Input
                                placeholder={t('password')}
                                addonAfter={
                                    <Tooltip placement="top" title={t('generate_password')}>
                                        <ControlOutlined onClick={handleGeneratePassword} />
                                    </Tooltip>
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 8 }}>
                        <Form.Item<UserRequest>
                            name="country_id"
                            label={t('country')}
                            rules={[{ required: true, message: t('common:rule_required') }]}
                            className="mb-0"
                        >
                            <Select
                                placeholder={t('country')}
                                options={countries?.data.paginated}
                                fieldNames={{ value: 'id', label: 'title' }}
                                showSearch
                                optionFilterProp={'title'}
                                onChange={onCountryChange}
                            ></Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 8 }}>
                        <Form.Item<UserRequest>
                            name="region_id"
                            label={t('region')}
                            rules={[{ required: true, message: t('common:rule_required') }]}
                            className="mb-0"
                            shouldUpdate
                        >
                            <Select
                                placeholder={t('region')}
                                options={regions?.data.paginated}
                                fieldNames={{ value: 'id', label: 'title' }}
                                showSearch
                                optionFilterProp={'title'}
                                disabled={!countryValue}
                            ></Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 8 }}>
                        <Form.Item<UserRequest> name="address" label={t('address')} className="mb-0">
                            <Input placeholder={t('address')} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col xs={{ span: 12 }} md={{ span: 4 }}>
                        <Button type="primary" style={{width: '100%'}} htmlType="submit" loading={isPending}>
                            {t('common:submit')}
                        </Button>
                    </Col>
                    <Col xs={{ span: 12 }} md={{ span: 4 }}>
                        <Button onClick={onCloseModal} style={{width: '100%'}} htmlType="button">
                            {t('common:cancel')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
