import React, { useRef } from 'react';
import { animated, useTransition } from '@react-spring/web';
import { Flex } from 'antd';
import logo from '@/assets/logo.svg';
import chevron_left from '@/assets/chevron-left.svg';
import { CustomMenu } from '@/layouts/components/CustomMenu.tsx';

export type MobileMenuDrawerProps = {
    show: boolean;
    close: () => void;
};

export const MobileMenuDrawer: React.FC<MobileMenuDrawerProps> = ({ show, close }) => {
    const drawerRef = useRef(null);

    const transition = useTransition(show, {
        from: { transform: 'translateX(-100%)' },
        enter: { transform: 'translateX(0%)' },
        leave: { transform: 'translateX(-100%)' },
    });

    const handleClick = (ev: EventTarget) => {
        if (ev != drawerRef.current) {
            close();
        }
    };

    return transition((style, item) => (
        <>
            {item && (
                <div className="h-dvh w-dvw fixed z-10" onClick={(ev) => handleClick(ev.target)}>
                    <animated.div
                        style={{
                            height: '100%',
                            width: '270px',
                            position: 'relative',
                            ...style,
                        }}
                    >
                        <div className="bg-white h-full w-[260px]" ref={drawerRef}>
                            <Flex vertical align="center" justify="start" className="pt-[31px] pl-[20px] w-max" gap={17}>
                                <img src={logo} className="logo cursor-pointer w-[147px] h-[24px]" alt="Akvantis" />
                                <CustomMenu />
                            </Flex>
                        </div>
                        <div
                            onClick={close}
                            className="w-[24px] h-[24px] cursor-pointer absolute rounded-[13px] right-0 top-[31px] inline-flex items-center justify-center bg-white drop-shadow"
                        >
                            <img src={chevron_left} className="w-[14px] h-[14px]" />
                        </div>
                    </animated.div>
                </div>
            )}
        </>
    ));
};
