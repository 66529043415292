import React, { useState } from 'react';
import { useListOfDevices } from '@/features/client-mobile/hooks/api.ts';
import { Badge, List, Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import rightArrow from '@/assets/blue_right_arrow.svg';
import { ClientDevice } from '@/types.ts';
import { ROUTES } from '@/features/router';
import { useNavigate } from 'react-router-dom';
import { StatusBadge } from '@/features/common/component/StatusBadge.tsx';

const makeUrl = (device: ClientDevice) => {
    switch (device.device_type) {
        case 'OSMO_PRO':
            return `${ROUTES.DEVICES_VIEW}/${device.id}`;
        case 'OSMO':
            return `${ROUTES.OSMO_DEVICES_VIEW}/${device.id}`;
    }
};

export const ClientDevicesTable: React.FC = () => {
    const [page, setPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(5);

    const { data, isLoading } = useListOfDevices(page, perPage);
    const { t } = useTranslation('devices');
    const navigate = useNavigate();
    return (
        <>
            <div className="flex flex-col gap-y-4">
                <List
                    className="bg-white px-[10px]"
                    loading={isLoading}
                    dataSource={data?.data?.paginated}
                    renderItem={(item) => (
                        <List.Item>
                            <div
                                className="flex flex-row w-full"
                                onClick={() => {
                                    navigate(makeUrl(item));
                                }}
                            >
                                <div className="flex flex-col flex-[1_1_66.66%] items-start justify-between gap-y-2">
                                    <span className="text-[14px] leading-[22px] font-semibold">{item.device_title}</span>
                                    {item.status && <StatusBadge status={item.status} />}
                                    <span className="text-[12px] leading-[22px] font-normal">{item.address}</span>
                                </div>
                                <div className="flex flex-col flex-[1_0_max-content] items-end justify-between">
                                    {item.state ? <Badge status="success" text={t('online')} /> : <Badge status="error" text={t('offline')} />}
                                    <img src={rightArrow} className="size-[14px]" />
                                </div>
                            </div>
                        </List.Item>
                    )}
                />
                <Pagination
                    align="center"
                    current={page}
                    defaultPageSize={perPage}
                    total={data?.data?.meta?.total}
                    onChange={(page, pageSize) => {
                        setPage(page);
                        setPerPage(pageSize);
                    }}
                    showLessItems={true}
                    hideOnSinglePage={true}
                />
            </div>
        </>
    );
};
