import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '@/features/router';
import { Breadcrumb, Button, Flex, Space } from 'antd';
import { useGetDevice } from '@/features/devices';
import { DeviceIframe } from '@/features/devices/component/DeviceIframe.tsx';
import { SettingOutlined, BarChartOutlined } from '@ant-design/icons';
import { makeDeviceBreadcrumbs } from '@/utils/helper.ts';

export const Device: React.FC = () => {
    const { t } = useTranslation('breadcrumbs');
    const { id } = useParams();
    const { data } = useGetDevice(parseInt(id!));
    const navigate = useNavigate();

    const breadcrumbItems = [
        {
            title: <Link to={ROUTES.DASHBOARD}>{t('breadcrumbs:home')}</Link>,
        },
        {
            title: <Link to={`${ROUTES.DEVICE_EDIT}/${id}`}>{makeDeviceBreadcrumbs(data?.data)}</Link>,
        },
    ];

    return (
        <Flex vertical gap={15}>
            <Flex justify="space-between" align="center">
                <Breadcrumb items={breadcrumbItems} />
                <Space>
                    <Button htmlType="button" onClick={() => navigate(`${ROUTES.DEVICE_NOTIFICATIONS}/${id}`)} icon={<SettingOutlined />}>
                        {t('devices:notification_settings')}
                    </Button>
                    <Button htmlType="button" onClick={() => navigate(`${ROUTES.ANALYTICS_VIEW}/${id}`)} icon={<BarChartOutlined />}>
                        {t('devices:analytics')}
                    </Button>
                </Space>
            </Flex>
            <DeviceIframe device={data?.data} />
        </Flex>
    );
};
