import React from 'react';
import { AntdProvider } from '@/features/antd';
import { TranslationProvider } from '@/features/i18n';
import { AuthContextProvider } from '@/features/auth';
import { ReactQueryProvider } from '@/features/react-query';
import { AxiosProvider } from '@/features/axios';
import { MessageApiContextProvider } from '@/features/message-api/context';
import { AdaptiveContextProvider } from '@/features/adaptive/context/Provider.tsx';

interface ProvidersProps extends React.PropsWithChildren {}

export const Providers: React.FC<ProvidersProps> = ({ children }) => {
    return (
        <TranslationProvider>
            <AuthContextProvider>
                <AxiosProvider>
                    <ReactQueryProvider>
                        <AntdProvider>
                            <MessageApiContextProvider>
                                <AdaptiveContextProvider>{children}</AdaptiveContextProvider>
                            </MessageApiContextProvider>
                        </AntdProvider>
                    </ReactQueryProvider>
                </AxiosProvider>
            </AuthContextProvider>
        </TranslationProvider>
    );
};
