import React from 'react';
import {MessageApiContext} from './Context.ts';
import useMessage from 'antd/es/message/useMessage';


interface MessageApiContextProviderProps extends React.PropsWithChildren {}
export const MessageApiContextProvider: React.FC<MessageApiContextProviderProps> = ({children}) => {
	const [messageApi, contextHolder] = useMessage();

	return (
		<MessageApiContext.Provider value={{messageApi}}>
			{contextHolder}
			{children}
		</MessageApiContext.Provider>
	);
};
