import { useAxios } from '@/features/axios';
import { useQuery } from '@tanstack/react-query';
import { API_ROUTES } from '@/api/routes.ts';
import { FlowAnalyticsFilter, FlowAnalyticsResponse } from '@/types.ts';
export const useGetAnalytics = (id: number, filterBy?: FlowAnalyticsFilter) => {
	const axios = useAxios();
	return useQuery({
		queryKey: [API_ROUTES.FLOW_ANALYTICS.KEY, filterBy],
		queryFn: async () => {

			const params = {
                filterBy: {} as FlowAnalyticsFilter
            };

            if (filterBy) {
                params.filterBy = filterBy;
            }

			return (await axios.get<FlowAnalyticsResponse>(`${API_ROUTES.FLOW_ANALYTICS.URL}/${id}`, {
				params
			})).data;
		}
	});
};
