import { useAxios } from '@/features/axios';
import { useMutation } from '@tanstack/react-query';
import { API_ROUTES } from '@/api/routes.ts';

export const useSetLang = () => {
    const axios = useAxios();

    return useMutation({
        mutationFn: async (lang: string) => {
            return (await axios.post(API_ROUTES.LANGUAGE.URL, { language: lang })).data;
        },
    });
};
