import { Card, Flex, TableProps } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import React, { useState } from 'react';
import { AnyObject } from 'antd/es/_util/type';
import { DeviceAccident, DeviceAccidentFilter, DeviceAccidentSortBy, DeviceEntityClass, SortOrder } from '@/types';
import { ALARM_TYPE } from '../constants';
import '@/styles/accidents.css';
import { useDeviceEvents } from '@/features/accidents/hooks';
import { useGetDeviceEventsHelpers } from '@/features/accidents/hooks/api.ts';
import { AccidentsFilter } from '@/features/accidents/components/AccidentsFilter.tsx';
import { AdaptiveTable } from '@/features/adaptive/components/AdaptiveTable.tsx';

const addClass = (record: AnyObject): string => {
    if ((record?.priority !== 4 || record?.priority !== 5 || record?.priority !== 6) && record?.event_id !== ALARM_TYPE.ONLINE) {
        return 'bg-rowError';
    }
    return '';
};

export type AccidentsTableProps = {
    entity_class: DeviceEntityClass;
    id: number;
};

export const AccidentsTable: React.FC<AccidentsTableProps> = ({ entity_class, id }) => {
    const { t, i18n } = useTranslation(['accidents', 'accidents_text', 'accidents_status']);

    const [page, setPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [filter, setFilter] = useState<DeviceAccidentFilter>();
    const [sortBy, setSortBy] = useState<DeviceAccidentSortBy | undefined>({
        event_date: 'desc',
    });

    const { data: events, isLoading, refetch } = useDeviceEvents(id, page, i18n.language, entity_class, perPage, filter, sortBy);
    const { data: accidents } = useGetDeviceEventsHelpers(id, i18n.language, entity_class);

    const getDotColor = (row: DeviceAccident) => (row.event_id === ALARM_TYPE.ONLINE ? 'bg-greenDot' : 'bg-redDot');

    const columns: TableProps<DeviceAccident>['columns'] = [
        {
            title: t('accidents:alert_time'),
            dataIndex: 'event_date',
            key: 'date',
            sorter: true,
            defaultSortOrder: 'descend',
            sortDirections: ['descend', 'ascend', 'descend'],
            width: '231px',
            render: (row: string, record: DeviceAccident) => (
                <p className="inline-flex items-center gap-2">
                    <span className={`w-[6px] h-[6px] rounded-[50%] ${getDotColor(record)}`}></span>
                    {moment(row).format('DD.MM.YYYY / HH:mm:ss')}
                </p>
            ),
        },
        {
            title: t('accidents:alert_reason'),
            dataIndex: 'comment',
            key: 'comment',
            render: (row?: string) => <p>{row ?? ''}</p>,
        },
    ];

    const handleTableChange: TableProps<DeviceAccident>['onChange'] = (pagination, _filters, sorter) => {
        if (pagination?.current) {
            setPage(pagination.current);
        }

        if (pagination?.pageSize) {
            setPerPage(pagination.pageSize);
        }

        if (!Array.isArray(sorter)) {
            let sortOrder: SortOrder;
            if (sorter.order === 'descend') {
                sortOrder = 'desc';
            } else if (sorter.order === 'ascend') {
                sortOrder = 'asc';
            }

            setSortBy({
                event_date: sortOrder,
            });
        }
    };

    return (
        <>
            <AccidentsFilter handleFilter={setFilter} refresh={refetch} accidents={accidents?.data?.data} loading={isLoading} />
            <Card>
                <AdaptiveTable
                    dataSource={events?.data?.paginated}
                    columns={columns}
                    rowKey="id"
                    onChange={handleTableChange}
                    rowClassName={(record) => addClass(record)}
                    loading={isLoading}
                    pagination={{
                        current: page,
                        total: events?.data.meta?.total,
                        defaultPageSize: perPage,
                        showSizeChanger: true,
                        showTotal: (total, range) =>
                            t('common:paginationTotal', {
                                range1: range[0],
                                range2: range[1],
                                total: total,
                            }),
                    }}
                    collapseLabel={(record) => (
                        <Flex justify="space-between" align="center" className='text-sm leading-4'>
                            <span className='text-left flex-[1_0_min-content]'>{t('accidents:alert_time')}</span>
                            <p className="inline-flex items-center gap-2 leading-[22px] flex-[0_1_auto]">
                                <span className={`w-[6px] h-[6px] rounded-[50%] ${getDotColor(record)}`}></span>
                                <span>{moment(record.event_date).format('DD.MM.YYYY / HH:mm:ss')}</span>
                            </p>
                        </Flex>
                    )}
                    omitColumns={['date']}
                />
            </Card>
        </>
    );
};
