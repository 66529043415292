import React from 'react';
import wg_off from '@/assets/wireguard_off.svg';
import wg_on from '@/assets/wireguard_on.svg';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

type WireguardIconProps = {
    enabled: boolean;
};

export const WireguardIcon: React.FC<WireguardIconProps> = ({ enabled }) => {
    const { t } = useTranslation('devices');
    return (
        <Tooltip placement='topLeft' title={enabled ? t('wireguard_enabled') : t('wireguard_disabled')}>
            <img className='max-w-[32px] max-h-[32px]' src={enabled ? wg_on : wg_off} alt={t('wireguard_public')}></img>
        </Tooltip>
    );
};
