import { UsersTable } from '@/features/users';
import { Breadcrumb, Button, Col, Row } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '@/features/router';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { useRBAC } from '@/features/auth';

export const Users = () => {
    const { isClient } = useRBAC();
    const { t } = useTranslation('common');
    const navigate = useNavigate();

    const breadcrumbItems = [
        {
            title: <Link to={ROUTES.DASHBOARD}>{t('breadcrumbs:home')}</Link>,
        },
        {
            title: t('breadcrumbs:users'),
        },
    ];

    return (
        <>
            <Row className="mb-4 flex-col md:flex-row gap-y-4">
                <Col xs={{ flex: '1 0 100%' }} md={{ flex: '1 0 auto' }}>
                    <Breadcrumb items={breadcrumbItems} />
                </Col>
                {!isClient() && (
                    <Col xs={{ flex: '1 0 100%' }} md={{ flex: '1 0 auto' }}>
                        <Button type="primary" className="text-sm float-left md:float-right" size={'middle'} icon={<PlusOutlined />} onClick={() => navigate(ROUTES.USER_ADD)}>
                            {t('users:add_user')}
                        </Button>
                    </Col>
                )}
            </Row>

            <Row>
                <Col span={24}>
                    <UsersTable />
                </Col>
            </Row>
        </>
    );
};
