import { Button, Card, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMessageApiContext } from '@/features/message-api/context';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/features/router';
import { setFormServerErrors } from '@/utils';
import { DeviceModelRequest } from '@/types.ts';
import { useAddDeviceModel } from './../hooks';

export const DeviceModelsAddForm = () => {
    const { mutateAsync, isPending } = useAddDeviceModel();
    const { t } = useTranslation('device_models');
    const { messageApi } = useMessageApiContext();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const onSubmit = async (values: DeviceModelRequest) => {
        try {
            await mutateAsync({
                title: values.title,
            });
            messageApi.open({
                type: 'success',
                content: t('common:message_success'),
            });
            navigate(ROUTES.DEVICE_MODELS);
        } catch (e) {
            messageApi.open({
                type: 'error',
                content: t('common:message_error'),
            });
            if (axios.isAxiosError(e)) {
                const errors = e.response!.data.errors;
                setFormServerErrors(form, errors);
            }
        }
    };

    return (
        <Card title={t('add_device_model')}>
            <Form form={form} layout="vertical" onFinish={onSubmit} requiredMark={false}>
                <Form.Item<DeviceModelRequest>
                    name="title"
                    label={t('device_model')}
                    rules={[{ required: true, message: t('common:rule_required') }]}
                >
                    <Input placeholder={t('device_model')} />
                </Form.Item>
                <Button type="primary" htmlType="submit" loading={isPending}>
                    {t('common:submit')}
                </Button>
                <Button
                    onClick={() => {
                        navigate(ROUTES.DEVICE_MODELS);
                    }}
                    htmlType="button"
                    className="ml-2"
                >
                    {t('common:cancel')}
                </Button>
            </Form>
        </Card>
    );
};
