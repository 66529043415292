import tg_off from '@/assets/telegram_off.svg'
import tg_on from '@/assets/telegram_on.svg'

import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type TelegramIconProps = {
    enabled: boolean;
}

export const TelegramIcon: React.FC<TelegramIconProps> = ({enabled}) => {
    const { t } = useTranslation('users');
    return (
        <Tooltip placement='topLeft' title={enabled ? t('tg_enabled') : t('tg_disabled')}>
            <img className='max-w-[24px] max-h-[24px]' src={enabled ? tg_on : tg_off}></img>
        </Tooltip>
    )
}
