import { AxiosContext } from '@/features/axios/context/Context.ts';
import React, { useMemo } from 'react';
import axios from 'axios';
import { useAuthContext } from '@/features/auth';

interface AxiosProviderProps extends React.PropsWithChildren {}
export const AxiosProvider: React.FC<AxiosProviderProps> = ({ children }) => {
    const { removeCredentials } = useAuthContext();

    const client = useMemo(() => {
        const axiosInstance = axios.create({
            baseURL: import.meta.env.VITE_BACKEND_URL,
            headers: {
                'Content-Type': 'application/json',
                // 'X-Requested-With': 'XMLHttpRequest'
            },
            withCredentials: true,
            withXSRFToken: true,
        });

        axiosInstance.interceptors.response.use(
            (response) => {
                return response;
            },
            function (error) {
                if (401 === error.response.status) {
                    removeCredentials();
                    // handle error: inform user, go to login, etc
                } else {
                    return Promise.reject(error);
                }
            }
        );

        /*axiosInstance.interceptors.request.use(config => {
			// Read token for anywhere, in this case directly from localStorage
			const token = localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY);
			if (token) {
				config.headers.Authorization = `Bearer ${token}`;
			}

			return config;
		});*/

        return axiosInstance;
    }, [removeCredentials]);
    return <AxiosContext.Provider value={client}>{children}</AxiosContext.Provider>;
};
