import { Button, Card, Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMessageApiContext } from '@/features/message-api/context';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/features/router';
import { setFormServerErrors } from '@/utils';
import { RegionRequest } from '@/types.ts';
import { useAddRegion } from '@/features/regions';
import { useCountries } from '@/features/countries';

export const RegionAddForm = () => {
    const { mutateAsync, isPending } = useAddRegion();
    const { data: countries } = useCountries(1, 100);
    const { t } = useTranslation('regions common');
    const { messageApi } = useMessageApiContext();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const onSubmit = async (values: RegionRequest) => {
        try {
            await mutateAsync({
                title: values.title,
                country_id: values.country_id,
            });
            messageApi.open({
                type: 'success',
                content: t('common:message_success'),
            });
            navigate(ROUTES.REGIONS);
        } catch (e) {
            messageApi.open({
                type: 'error',
                content: t('common:message_error'),
            });
            if (axios.isAxiosError(e)) {
                const errors = e.response!.data.errors;
                setFormServerErrors(form, errors);
            }
        }
    };

    return (
        <Card title={t('regions:add_region')}>
            <Form form={form} name="form_add_region" layout="vertical" onFinish={onSubmit} requiredMark={false}>
                <div className="grid grid-flow-row md:grid-cols-2 gap-x-4">
                    <Form.Item<RegionRequest>
                        name="country_id"
                        label={t('regions:country')}
                        rules={[{ required: true, message: t('common:rule_required') }]}
                    >
                        <Select
                            placeholder={t('regions:country')}
                            options={countries?.data.paginated}
                            fieldNames={{ value: 'id', label: 'title' }}
                            showSearch
                            optionFilterProp={'title'}
                        ></Select>
                    </Form.Item>
                    <Form.Item<RegionRequest>
                        name="title"
                        label={t('regions:region')}
                        rules={[{ required: true, message: t('common:rule_required') }]}
                    >
                        <Input placeholder={t('regions:region')} />
                    </Form.Item>
                </div>
                <Button type="primary" htmlType="submit" loading={isPending}>
                    {t('common:submit')}
                </Button>
                <Button
                    onClick={() => {
                        navigate(ROUTES.REGIONS);
                    }}
                    htmlType="button"
                    className="ml-2"
                >
                    {t('common:cancel')}
                </Button>
            </Form>
        </Card>
    );
};
