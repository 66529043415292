import { createContext, useContext } from 'react';

interface UserLocaleContextProps {
    userLocale: string;
    setUserLocale: (locale: string) => void;
}

export const UserLocaleContext = createContext<UserLocaleContextProps>({
    userLocale: 'uk',
    setUserLocale: () => {},
});

export const useUserLocaleContext = () => useContext(UserLocaleContext);
