import React, { useEffect, useState } from 'react';
import { UserLocaleContext } from '@/features/i18n/context/Context.ts';
import { useTranslation } from 'react-i18next';

interface UserLocaleProviderProps extends React.PropsWithChildren {}
export const UserLocaleProvider: React.FC<UserLocaleProviderProps> = ({ children }) => {
    const [userLocale, setUserLocale] = useState<string>('uk');

    const { i18n } = useTranslation();

    useEffect(() => {
        i18n.changeLanguage(userLocale);
    }, [userLocale]);

    return <UserLocaleContext.Provider value={{ userLocale, setUserLocale }}>{children}</UserLocaleContext.Provider>;
};
