import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGetDeviceData } from '@/features/client-mobile/hooks/api.ts';
import { ROUTES } from '@/features/router';
import { OsmoProDeviceData } from '@/types.ts';
import { Breadcrumb, Button, Col, Row, Spin } from 'antd';
import { ClientStatusBadge } from '@/features/client-mobile/components/ClientStatusBadge.tsx';
import { LineChartOutlined, WarningOutlined } from '@ant-design/icons';
import { useTransition, animated } from '@react-spring/web';
import car_icon from '@/assets/car.svg';

export const ClientOsmoProDevice: React.FC = () => {
    const { id } = useParams();
    const { t } = useTranslation('breadcrumbs');
    const navigate = useNavigate();

    const { data, isLoading } = useGetDeviceData<OsmoProDeviceData>(parseInt(id!), 'device');

    const [percent, setPercent] = useState<number>(0);

    const barTransition = useTransition(percent, {
        from: { top: '100%' },
        enter: { top: `${100 - percent}%` },
        leave: { top: `${100 - percent}%` },
    });

    const breadcrumbItems = [
        {
            title: <Link to={ROUTES.DASHBOARD}>{t('home')}</Link>,
        },
        {
            title: `${data?.data?.title}, ${data?.data?.address ?? ''}`,
        },
    ];

    useEffect(() => {
        setPercent(data?.data.data.LEVEL_TANK ?? 0);
    }, [isLoading]);

    return (
        <>
            <Spin spinning={isLoading} fullscreen />

            <Row gutter={[16, 16]} className="mb-[29px]">
                <Col span={24}>
                    <Breadcrumb items={breadcrumbItems} />
                </Col>
                <Col span={24}>
                    <ClientStatusBadge state={data?.data?.state} status={data?.data?.status} />
                </Col>
            </Row>

            <div className="flex flex-col gap-y-5 items-center">
                <span className="text-[20px] leading-[24px] font-medium uppercase">{t('devices')}</span>
                <div className="grid grid-[max-content_1fr_max-content] gap-y-[8px] w-full">
                    <div className="px-[12px] py-[14px] bg-white rounded-[8px]">
                        <Row gutter={[10, 10]}>
                            <Col flex="1 0 50%">
                                <div className="bg-[#F4F6F8] px-[15px] py-[10px] rounded-[6px] grid grid-rows-2 gap-y-[5px] h-full">
                                    <span className="text-[12px] leading-[14px] font-normal text-[#00000073]">TDS Before</span>
                                    <span className="text-[14px] leading-[21px] font-medium text-[#F5222D] self-end">
                                        {data?.data?.data?.TDS_BEFORE ?? 0}
                                    </span>
                                </div>
                            </Col>
                            <Col flex="1 0 50%">
                                <div className="bg-[#F4F6F8] px-[15px] py-[10px] rounded-[6px] grid grid-rows-2 gap-y-[5px] h-full">
                                    <span className="text-[12px] leading-[14px] font-normal text-[#00000073]">TDS After</span>
                                    <div className="flex justify-between w-full">
                                        <span className="text-[14px] leading-[21px] font-medium text-[#0050B3] self-end">
                                            {data?.data?.data?.TDS_AFTER ?? 0}
                                        </span>
                                        <span className="text-[20px] leading-[26px] font-medium text-[#0050B3]">
                                            {data?.data?.data?.TDS_AFTER_PERCENT ?? 0}%
                                        </span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="grid grid-cols-3 gap-x-[8px]">
                        <div className="flex flex-col gap-y-[8px]">
                            <div className="bg-white px-[12px] py-[14px] rounded-[8px] grid grid-rows-2 gap-y-[5px]">
                                <span className="text-[12px] leading-[14px] text-[#00000073] font-normal">Today, L</span>
                                <span className="text-[20px] leading-[30px] text-[#000000D9] font-medium">{data?.data?.data?.PERMEAT ?? 0}</span>
                            </div>
                            <div className="bg-white px-[12px] py-[14px] rounded-[8px] grid grid-rows-2 gap-y-[5px]">
                                <span className="text-[12px] leading-[14px] text-[#00000073] font-normal">Flow rate, L/m</span>
                                <span className="text-[20px] leading-[30px] text-[#000000D9] font-medium">{data?.data?.data?.FLOW_RATE ?? 0}</span>
                            </div>
                            <div className="bg-white px-[12px] py-[14px] rounded-[8px] grid grid-rows-2 gap-y-[5px]">
                                <span className="text-[12px] leading-[14px] text-[#00000073] font-normal">Total, L</span>
                                <span className="text-[20px] leading-[30px] text-[#000000D9] font-medium">
                                    {data?.data?.data?.TOTAL_PERMEAT_L ?? 0}
                                </span>
                            </div>
                        </div>
                        <div className="h-full relative flex items-center justify-center overflow-hidden rounded-[8px] border border-[#0050B3]">
                            <span
                                className={`${percent >= 55 ? 'text-white' : 'text-black'} text-[20px] leading-[30px] font-medium z-10`}
                            >{`${percent}%`}</span>
                            {barTransition((style) => (
                                <animated.div style={style} className="absolute w-full h-full bottom-0 left-0 bg-[#0050B3]"></animated.div>
                            ))}
                        </div>
                        <div className="flex flex-col gap-y-[8px]">
                            <div className="bg-white px-[12px] py-[14px] rounded-[8px] grid grid-rows-2 gap-y-[5px]">
                                <span className="text-[12px] leading-[14px] text-[#00000073] font-normal">t °C</span>
                                <span className="text-[20px] leading-[30px] text-[#000000D9] font-medium">{data?.data?.data?.INLET_TEMP ?? 0}</span>
                            </div>
                        </div>
                    </div>

                    <div className="flex bg-white rounded-[8px] flex-row px-[12px] py-[14px] h-full items-center justify-between">
                        <div className="flex flex-row gap-x-[10px] items-center">
                            <img src={car_icon} className="w-[44px] h-[26px]" />
                            <span className="text-[16px] leading-[24px] font-medium">{t('osmo_devices:UP_TO_THE_SERVICE')}</span>
                        </div>
                        <span className="text-[20px] leading-[30px] font-medium">{data?.data?.data?.VOLUME_SERVICE_1 ?? 0}</span>
                    </div>
                </div>
            </div>

            <div className="flex justify-end fixed bottom-[19px] right-[10px] gap-x-[15px]">
                <Button
                    type="primary"
                    icon={<LineChartOutlined />}
                    className="w-max"
                    onClick={() => {
                        navigate(`${ROUTES.ANALYTICS_VIEW}/${data?.data?.id}`, { state: { prevLocation: location.pathname } });
                    }}
                >
                    {t('analytics')}
                </Button>
                <Button
                    type="primary"
                    icon={<WarningOutlined />}
                    className="w-max"
                    onClick={() => {
                        navigate(`${ROUTES.DEVICE_ACCIDENTS}/${data?.data?.id}`, { state: { prevLocation: location.pathname } });
                    }}
                >
                    {t('accidents')}
                </Button>
            </div>
        </>
    );
};
