import { Device } from '@/types.ts';

type DeviceIframeProps = {
    device?: Device;
};

export const DeviceIframe: React.FC<DeviceIframeProps> = ({ device }) => {
    const generateIframeLink = (device: Device) => {
        return device.uri.replace('https://', `https://${device.login}:${device.password}@`);
    };

    return device && <iframe className="h-dvh" src={generateIframeLink(device)}></iframe>;
};
