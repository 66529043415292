import React from 'react';
import { useMikrotikSettings } from '@/features/devices/hooks/api.ts';
import { useTranslation } from 'react-i18next';
import { Button, Col, Modal, Row } from 'antd';
import { useMessageApiContext } from '@/features/message-api/context';

type MikrotikSettingsModalProps = {
    deviceId: number;
    isOpen: boolean;
    handleClose: () => void;
};

export const MikrotikSettingsModal: React.FC<MikrotikSettingsModalProps> = ({ isOpen = false, handleClose, deviceId }) => {
    const { data: mikrotikSettings } = useMikrotikSettings(deviceId);
    const { t } = useTranslation('devices');
    const { messageApi } = useMessageApiContext();

    const handleCopy = () => {
        if (mikrotikSettings?.data != null) {
            navigator.clipboard.writeText(mikrotikSettings?.data).then(() => {
                messageApi.open({
                    type: 'success',
                    content: t('common:copy_success'),
                });
            });
        }
    };

    return (
        <Modal open={isOpen} title={t('mikrotik_settings')} onCancel={handleClose} destroyOnClose width={840} footer={null} getContainer={false}>
            <pre className="overflow-auto bg-gray-200 text-black p-2 mb-2">{mikrotikSettings?.data}</pre>
            <Row gutter={[8, 8]}>
                <Col xs={{ span: 12 }} md={{ span: 4 }}>
                    <Button type="primary" style={{width: '100%'}} htmlType="button" onClick={handleCopy}>
                        {t('common:copy')}
                    </Button>
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 4 }}>
                    <Button htmlType="button" style={{width: '100%'}} onClick={handleClose}>
                        {t('common:cancel')}
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};
