import { ItemType } from 'antd/es/menu/interface';
import React from 'react';
import { Flex } from 'antd';
import { MenuItemGroupType } from 'rc-menu/lib/interface';

export type MobileMenuProps = {
    selectedKeys?: string[];
    items?: ItemType[];
};

export const MobileMenu: React.FC<MobileMenuProps> = ({ selectedKeys, items }) => {
    const modifyItem = (item: React.ReactElement, active: boolean) => {
        return React.cloneElement(item, {
            className: `${active ? 'text-[#0050B3]' : 'text-black'}`,
        });
    };

    return (
        <>
            {items && (
                <Flex vertical align="start" justify="center" className="w-full">
                    {items
                        .filter((el) => el !== null)
                        .map((item) => (
                            <div className={`py-[13px] font-normal text-[14px] leading-[22px]`} key={item?.key}>
                                {modifyItem((item as MenuItemGroupType).label, selectedKeys && selectedKeys.includes(item!.key!.toString()))}
                            </div>
                        ))}
                </Flex>
            )}
        </>
    );
};
