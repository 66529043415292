import React from 'react';
import { useRBAC } from '@/features/auth';
import { useAdaptiveContext } from '@/features/adaptive/context/Context.ts';

interface ClientMobileWrapperProps extends React.PropsWithChildren {
    defaultChild: React.ReactNode;
}

export const ClientMobileWrapper: React.FC<ClientMobileWrapperProps> = ({ defaultChild, children }) => {
    const { isClient } = useRBAC();
    const { isTabletOrMobile } = useAdaptiveContext();

    return <>{(isClient() && isTabletOrMobile && children) || defaultChild}</>;
};
