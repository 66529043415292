import { Card, Col, Row, Statistic } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useCountData } from '@/features/devices/hooks/api.ts';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useAdaptiveContext } from '@/features/adaptive/context/Context.ts';

type DevicesStatisticsProps = {};

export const DevicesStatistics: React.FC<DevicesStatisticsProps> = () => {
    const { t } = useTranslation('devices');
    const { data, isLoading } = useCountData();
    const { isTabletOrMobile } = useAdaptiveContext();

    return (
        <Row gutter={[16, 8]}>
            <Col xs={{ span: 24 }} md={{ span: 12 }} className="md:max-w-[353px]">
                <Card loading={isLoading} styles={{ body: { paddingTop: '14px', paddingBottom: '14px' } }}>
                    {(isTabletOrMobile && (
                        <div className="flex items-center gap-x-2">
                            <span className="text-[14px] flex-[1_1_auto]">{t('users_total')}</span>
                            <span className="text-[18px] font-semibold flex-[0_1_max-content]">{data?.data.users}</span>
                        </div>
                    )) || (
                        <Statistic
                            className="h-full"
                            title={
                                <div className={'flex justify-between'}>
                                    {t('users_total')} <InfoCircleOutlined style={{ color: 'black' }} />
                                </div>
                            }
                            value={data?.data.users}
                            valueStyle={{ fontSize: 30 }}
                        />
                    )}
                </Card>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 12 }} className="md:max-w-[353px]">
                <Card loading={isLoading} styles={{ body: { paddingTop: '14px', paddingBottom: '14px' } }}>
                    {(isTabletOrMobile && (
                        <div className="flex items-center gap-x-2">
                            <span className="text-[14px] flex-[1_1_auto]">{t('devices_total')}</span>
                            <span className="text-[18px] font-semibold flex-[0_1_max-content]">{data?.data.devices}</span>
                        </div>
                    )) || (
                        <Statistic
                            className="h-full"
                            title={
                                <div className={'flex justify-between'}>
                                    {t('devices_total')} <InfoCircleOutlined style={{ color: 'black' }} />
                                </div>
                            }
                            value={data?.data.devices}
                            valueStyle={{ fontSize: 30 }}
                        />
                    )}
                </Card>
            </Col>
        </Row>
    );
};
