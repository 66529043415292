import { Breadcrumb } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { ROUTES } from '@/features/router';
import { useTranslation } from 'react-i18next';
import { NotificationsSettingsForm } from '@/features/notification_info/component/NotificationsSettingsForm.tsx';

export const OsmoNotificationSettings = () => {
    const { t } = useTranslation('breadcrumbs');
    const { id } = useParams();

    const breadcrumbItems = [
        {
            title: <Link to={ROUTES.OSMO_DEVICES}>{t('breadcrumbs:home')}</Link>,
        },
        {
            title: <Link to={`${ROUTES.OSMO_DEVICES_ACCIDENTS}/${id}`}>{t('breadcrumbs:accidents')}</Link>,
        },
        {
            title: t('breadcrumbs:notification_settings'),
        },
    ];

    return (
        <>
            <Breadcrumb items={breadcrumbItems} className={'mb-4'} />

            <NotificationsSettingsForm entity_class="arduino_device" afterSuccessRoute={ROUTES.OSMO_DEVICES} />
        </>
    );
};
