import { useAxios } from '@/features/axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API_ROUTES } from '@/api/routes.ts';
import { CountriesTableCollection, CountriesTableSortBy, CountryRequest, CountryResponse } from '@/types.ts';

export const useCountries = (page: number, perPage?: number, sortBy?: CountriesTableSortBy) => {
    const axios = useAxios();

    return useQuery({
        queryKey: [API_ROUTES.COUNTRIES.KEY, page, perPage, sortBy],

        queryFn: async () => {
            let params = {
                sortBy: {} as CountriesTableSortBy,
                page: page,
                per_page: perPage,
            };

            if (sortBy?.title) {
                params.sortBy.title = sortBy.title;
            }

            return (
                await axios.get<CountriesTableCollection>(`${API_ROUTES.COUNTRIES.URL}`, {
                    params,
                })
            ).data;
        },
    });
};

export const useAddCountry = () => {
    const axios = useAxios();

    return useMutation({
        mutationFn: async (country: CountryRequest) => {
            return (await axios.post<CountryResponse>(API_ROUTES.COUNTRIES.URL, country)).data;
        },
    });
};

export const useGetCountry = (id: number) => {
    const axios = useAxios();
    return useQuery({
        queryKey: [API_ROUTES.COUNTRIES.KEY, id],
        queryFn: async () => {
            return (await axios.get<CountryResponse>(`${API_ROUTES.COUNTRIES.URL}/${id}`)).data;
        },
    });
};

export const useUpdateCountry = (id: number) => {
    const axios = useAxios();
    return useMutation({
        mutationFn: async (country: CountryRequest) => {
            return (await axios.put<CountryResponse>(`${API_ROUTES.COUNTRIES.URL}/${id}`, country)).data;
        },
    });
};

export const useDeleteCountry = () => {
    const axios = useAxios();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (id: number) => {
            return (await axios.delete<CountryResponse>(`${API_ROUTES.COUNTRIES.URL}/${id}`)).data;
        },
        onSuccess: async () => {
            await client.invalidateQueries({ queryKey: [API_ROUTES.COUNTRIES.KEY] });
        },
    });
};
