import { Menu } from 'antd';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useRBAC } from '@/features/auth';
import { MobileMenu } from '@/features/adaptive/components/MobileMenu.tsx';
import { useAdaptiveContext } from '@/features/adaptive/context/Context.ts';

type CustomMenuProps = {};

export const CustomMenu: React.FC<CustomMenuProps> = () => {
    const { pathname } = useLocation();
    const { menuDependingOnRole } = useRBAC();
    const { isTabletOrMobile } = useAdaptiveContext();

    const selectedKeys = useMemo(() => {
        return ['/' + pathname.split('/')[1]];
    }, [pathname]);

    return (
        <>
            {(isTabletOrMobile && <MobileMenu selectedKeys={selectedKeys} items={menuDependingOnRole} />) || (
                <Menu selectedKeys={selectedKeys} mode="horizontal" items={menuDependingOnRole} className={'flex-1 justify-center min-w-0'} />
            )}
        </>
    );
};
