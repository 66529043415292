import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, Button, Select, Card, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGetUser, useReferrers, useRoles, useUpdateUser } from '@/features/users';
import { UserRequest } from '@/types.ts';
import { useNavigate, useParams } from 'react-router-dom';
import { useMessageApiContext } from '@/features/message-api/context';
import { ROUTES } from '@/features/router';
import { ControlOutlined } from '@ant-design/icons';
import { useRegions } from '@/features/regions';
import axios from 'axios';
import { generatePassword, setFormServerErrors } from '@/utils';
import { useAuthContext, useRBAC } from '@/features/auth';
import { useProfile } from '@/features/profile';
import { useCountries } from '@/features/countries';
import IntlTelInput from 'intl-tel-input/reactWithUtils';
import 'intl-tel-input/build/css/intlTelInput.css';
import {initOptions} from '@/features/iti/config.ts';

export const UserEditForm: React.FC = () => {
    const { t } = useTranslation('users');
    const { id } = useParams();
    const { isAdmin } = useRBAC();
    const [form] = Form.useForm();
    const { i18n } = useTranslation();
    const countryValue = Form.useWatch('country_id', form);
    const { data: roles } = useRoles();
    const { data: referrers } = useReferrers(isAdmin());
    const { data: countries } = useCountries(1, 100);
    const { data: regions } = useRegions(1, 100, { country_id: countryValue });
    const { data, isLoading, isSuccess } = useGetUser(parseInt(id!));
    const { mutateAsync, isPending } = useUpdateUser(parseInt(id!));
    const { refetch: getProfile } = useProfile(false);
    const { setActiveUser } = useAuthContext();
    const { messageApi } = useMessageApiContext();
    const navigate = useNavigate();
    const ref = useRef(null);
    const [phone, setPhone] = useState<string | null>(null);
    const [isValidPhone, setIsValidPhone] = useState<boolean | null>(null);

    useEffect(() => {
        if (isSuccess) {
            form.setFieldsValue({
                counterparty: data.data.counterparty,
                role: data.data.role,
                referrer_id: data.data.referrer?.id,
                phone: data.data.phone,
                email: data.data.email,
                country_id: data.data.country.id,
                region_id: data.data.region.id,
                address: data.data.address,
            });

            // @ts-ignore
            ref.current?.getInstance().setNumber(data.data.phone);
        }
    }, [isSuccess, data, form]);

    useEffect(() => {
        // @ts-ignore
        ref.current?.getInstance().setNumber(data.data.phone);
    }, [i18n.language]);

    useEffect(() => {
        form.setFieldValue('phone', phone);
    }, [phone, form]);

    const onSubmit = async (values: UserRequest) => {
        try {
            let params: UserRequest = {
                counterparty: values.counterparty,
                phone: values.phone,
                email: values.email,
                password: values.password,
                country_id: values.country_id,
                region_id: values.region_id,
                address: values.address,
            };

            if (isAdmin()) {
                params.role = values.role;
                params.referrer_id = values.referrer_id;
            }

            await mutateAsync(params);

            const response = await getProfile();
            if (response?.data?.data) {
                setActiveUser(response.data.data);
            }

            messageApi.open({
                type: 'success',
                content: t('common:message_success'),
            });
            navigate(ROUTES.USERS);
        } catch (e) {
            messageApi.open({
                type: 'error',
                content: t('common:message_error'),
            });

            if (axios.isAxiosError(e)) {
                const errors = e.response!.data.errors;
                setFormServerErrors(form, errors);
            }
        }
    };

    const handleGeneratePassword = () => {
        form.setFieldValue('password', generatePassword());
    };

    const onCountryChange = () => {
        form.setFieldValue('region_id', null);
    };

    return (
        <Card title={t('edit_user')} loading={isLoading}>
            <Form name="form_profile_general" layout="vertical" form={form} onFinish={onSubmit} requiredMark={false}>
                <div className="grid grid-flow-row md:grid-cols-3 gap-x-4">
                    <Form.Item<UserRequest>
                        name="counterparty"
                        label={t('counterparty')}
                        rules={[{ required: true, message: t('common:rule_required') }]}
                    >
                        <Input placeholder={t('counterparty')} />
                    </Form.Item>

                    {isAdmin() && (
                        <Form.Item<UserRequest> name="role" label={t('role')} rules={[{ required: true, message: t('common:rule_required') }]}>
                            <Select placeholder={t('role')}>
                                {roles?.map((item) => (
                                    <Select.Option key={item} value={item}>
                                        {t(item, { ns: 'common' })}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}

                    {isAdmin() && (
                        <Form.Item<UserRequest> name="referrer_id" label={t('referrer')}>
                            <Select
                                placeholder={t('referrer')}
                                options={referrers?.data}
                                fieldNames={{ value: 'id', label: 'counterparty' }}
                                showSearch
                                optionFilterProp={'counterparty'}
                            ></Select>
                        </Form.Item>
                    )}

                    <Form.Item<UserRequest>
                        name="phone"
                        label={t('phone')}
                        rules={[
                            {
                                required: true,
                                message: t('common:rule_required'),
                            },
                            {
                                message: t('common:rule_invalid_number'),
                                validator: (_, value) => {
                                    if ((value && isValidPhone) || !value) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject();
                                    }
                                },
                            },
                        ]}
                    >
                        <div key={i18n.language}>
                            <IntlTelInput
                                ref={ref}
                                onChangeNumber={setPhone}
                                onChangeValidity={setIsValidPhone}
                                initOptions={initOptions(i18n)}
                            />
                        </div>
                    </Form.Item>
                    <Form.Item<UserRequest> name="email" label={t('email')} rules={[{ required: true, message: t('common:rule_required') }]}>
                        <Input placeholder={t('email')} />
                    </Form.Item>
                    <Form.Item<UserRequest> name="password" label={t('password')}>
                        <Input
                            placeholder={t('password')}
                            addonAfter={
                                <Tooltip placement="top" title={t('generate_password')}>
                                    <ControlOutlined onClick={handleGeneratePassword} />
                                </Tooltip>
                            }
                        />
                    </Form.Item>
                    <Form.Item<UserRequest> name="country_id" label={t('country')} rules={[{ required: true, message: t('common:rule_required') }]}>
                        <Select
                            placeholder={t('country')}
                            options={countries?.data.paginated}
                            fieldNames={{ value: 'id', label: 'title' }}
                            showSearch
                            optionFilterProp={'title'}
                            onChange={onCountryChange}
                        ></Select>
                    </Form.Item>
                    <Form.Item<UserRequest> name="region_id" label={t('region')} rules={[{ required: true, message: t('common:rule_required') }]}>
                        <Select
                            placeholder={t('region')}
                            options={regions?.data.paginated}
                            fieldNames={{ value: 'id', label: 'title' }}
                            showSearch
                            optionFilterProp={'title'}
                            disabled={!countryValue}
                        ></Select>
                    </Form.Item>
                    <Form.Item<UserRequest> name="address" label={t('address')}>
                        <Input placeholder={t('address')} />
                    </Form.Item>
                </div>
                <Button type="primary" htmlType="submit" loading={isPending}>
                    {t('common:submit')}
                </Button>
                <Button
                    onClick={() => {
                        navigate(ROUTES.USERS);
                    }}
                    htmlType="button"
                    className="ml-2"
                >
                    {t('common:cancel')}
                </Button>
            </Form>
        </Card>
    );
};
