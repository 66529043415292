import { createContext, useContext } from 'react';

type AdaptiveContextProps = {
    isMediaSm: boolean;
    isMediaMd: boolean;
    isMediaLg: boolean;
    isMediaXl: boolean;
    isMediaXxl: boolean;
    isDesktopOrLaptop: boolean;
    isTabletOrMobile: boolean;
};

export const AdaptiveContext = createContext<AdaptiveContextProps>({
    isMediaSm: false,
    isMediaMd: false,
    isMediaLg: false,
    isMediaXl: false,
    isMediaXxl: false,
    isDesktopOrLaptop: false,
    isTabletOrMobile: false,
});

export const useAdaptiveContext = () => useContext(AdaptiveContext);
