import { Tooltip, Modal, Card, Divider, Flex } from 'antd';
import type { TableProps } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CountriesTableSortBy, Country, SortOrder } from '@/types.ts';
import { ROUTES } from '@/features/router';
import { useState } from 'react';
import { useMessageApiContext } from '@/features/message-api/context';
import { ColumnsType, TableLocale } from 'antd/es/table/interface';
import { useCountries, useDeleteCountry } from '@/features/countries/hooks';
import axios from 'axios';
import { ERRORS } from '@/utils/constants.ts';
import { AdaptiveTable } from '@/features/adaptive/components/AdaptiveTable.tsx';

type ShowDeleteProps = {
    id: number;
    title: string;
};

export const CountriesTable = () => {
    const [page, setPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [sortBy, setSortBy] = useState<CountriesTableSortBy>();
    const { mutateAsync } = useDeleteCountry();
    const { data, isLoading } = useCountries(page, perPage, sortBy);
    const { t } = useTranslation(['users', 'common']);
    const navigate = useNavigate();
    const { confirm } = Modal;
    const { messageApi } = useMessageApiContext();

    const showDeleteConfirm = ({ id, title }: ShowDeleteProps) => {
        confirm({
            title: t('countries:delete_confirmation'),
            content: <span className="text-lg">{title}</span>,
            icon: <ExclamationCircleFilled />,
            okText: t('common:delete_ok'),
            okType: 'danger',
            cancelText: t('common:delete_cancel'),
            onOk: async () => {
                try {
                    await mutateAsync(id);
                    messageApi.open({
                        type: 'success',
                        content: t('common:message_success'),
                    });
                } catch (e) {
                    if (axios.isAxiosError(e)) {
                        let message = t('common:message_error');
                        if (e.response?.data.status === ERRORS.CONSTRAINT_ERROR) {
                            message = t('common:message_constraint_error');
                        }

                        messageApi.open({
                            type: 'error',
                            content: message,
                        });
                    }
                }
            },
            onCancel() {},
        });
    };

    const columns: ColumnsType<Country> = [
        {
            title: t('countries:country'),
            dataIndex: 'title',
            key: 'title',
            sorter: true,
        },
        {
            title: t('common:actions'),
            key: 'action',
            width: '92px',
            render: (_, record) => (
                <div className={'flex items-center'}>
                    <Tooltip placement="top" title={t('common:tooltip_edit')}>
                        <EditOutlined
                            onClick={() => {
                                navigate(`${ROUTES.COUNTRY_EDIT}/${record.id}`);
                            }}
                            style={{ color: '#389E0D', fontSize: 20 }}
                        />
                    </Tooltip>
                    <Divider type="vertical" />
                    <Tooltip placement="top" title={t('common:tooltip_delete')}>
                        <DeleteOutlined onClick={() => showDeleteConfirm(record)} style={{ color: '#CF1322', fontSize: 20 }} />
                    </Tooltip>
                </div>
            ),
        },
    ];

    const tableLocale: TableLocale = {
        emptyText: <div className={'py-[74px]'}> {t('common:emptyText')}</div>,
    };

    const handleTableChange: TableProps<Country>['onChange'] = (pagination, _filters, sorter) => {
        if (pagination?.current) {
            setPage(pagination.current);
        }

        if (pagination?.pageSize) {
            setPerPage(pagination.pageSize);
        }

        if (!Array.isArray(sorter)) {
            let sortOrder: SortOrder;
            if (sorter.order === 'descend') {
                sortOrder = 'desc';
            } else if (sorter.order === 'ascend') {
                sortOrder = 'asc';
            }

            if (sorter.columnKey === 'title') {
                setSortBy((prevState) => {
                    const newState = { ...prevState };
                    newState.title = sortOrder;
                    return newState;
                });
            }
        }
    };

    return (
        <>
            <Card bordered={false}>
                <AdaptiveTable<Country>
                    loading={isLoading}
                    columns={columns}
                    dataSource={data?.data.paginated}
                    rowKey="id"
                    onChange={handleTableChange}
                    pagination={{
                        current: page,
                        total: data?.data.meta?.total,
                        defaultPageSize: perPage,
                        showSizeChanger: true,
                        showTotal: (total, range) => t('common:paginationTotal', { range1: range[0], range2: range[1], total: total }),
                    }}
                    locale={tableLocale}
                    collapseLabel={(record) => (
                        <Flex justify="space-between" align="center">
                            <span>{t('countries:country')}</span>
                            <span>{record.title}</span>
                        </Flex>
                    )}
                    omitColumns={['title']}
                />
            </Card>
        </>
    );
};
