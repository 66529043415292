import { useAxios } from '@/features/axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API_ROUTES } from '@/api/routes.ts';
import { ReferrersCollection, UserRequest, UserResponse, UsersTableCollection, UsersTableFilter, UsersTableSortBy } from '@/types.ts';

export const useUsers = (page: number, perPage?: number, filterBy?: UsersTableFilter, sortBy?: UsersTableSortBy) => {
    const axios = useAxios();

    return useQuery({
        queryKey: [API_ROUTES.USERS.KEY, page, perPage, filterBy, sortBy],

        queryFn: async () => {
            let params = {
                filterBy: {} as UsersTableFilter,
                sortBy: {} as UsersTableSortBy,
                page: page,
                per_page: perPage,
            };

            if (filterBy) {
                if (filterBy.counterparty?.length) {
                    params.filterBy.counterparty = filterBy.counterparty;
                }
                if (filterBy.email?.length) {
                    params.filterBy.email = filterBy.email;
                }
                if (filterBy.phone?.length) {
                    params.filterBy.phone = filterBy.phone;
                }
                if (filterBy.role_slug?.length) {
                    params.filterBy.role_slug = filterBy.role_slug;
                }
            }

            if (sortBy?.counterparty) {
                params.sortBy.counterparty = sortBy.counterparty;
            }

            if (sortBy?.address) {
                params.sortBy.address = sortBy.address;
            }

            if (sortBy?.referrer) {
                params.sortBy.referrer = sortBy.referrer;
            }

            return (
                await axios.get<UsersTableCollection>(`${API_ROUTES.USERS.URL}`, {
                    params,
                })
            ).data;
        },
    });
};

export const useAddUser = () => {
    const axios = useAxios();

    return useMutation({
        mutationFn: async (user: UserRequest) => {
            return (await axios.post<UserResponse>(API_ROUTES.USERS.URL, user)).data;
        },
    });
};

export const useGetUser = (id: number) => {
    const axios = useAxios();
    return useQuery({
        queryKey: [API_ROUTES.USERS.KEY, id],
        queryFn: async () => {
            return (await axios.get<UserResponse>(`${API_ROUTES.USERS.URL}/${id}`)).data;
        },
        // enabled: false
    });
};

export const useUpdateUser = (id: number) => {
    const axios = useAxios();
    return useMutation({
        mutationFn: async (user: UserRequest) => {
            return (await axios.put<UserResponse>(`${API_ROUTES.USERS.URL}/${id}`, user)).data;
        },
    });
};

export const useDeleteUser = () => {
    const axios = useAxios();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (id: number) => {
            return (await axios.delete<UserResponse>(`${API_ROUTES.USERS.URL}/${id}`)).data;
        },
        onSuccess: async () => {
            await client.invalidateQueries({ queryKey: [API_ROUTES.USERS.KEY] });
        },
    });
};

export const useRoles = () => {
    const axios = useAxios();

    return useQuery({
        queryKey: [API_ROUTES.ROLES.KEY],

        queryFn: async () => {
            return (await axios.get<string[]>(API_ROUTES.ROLES.URL)).data;
        },
    });
};

export const useReferrers = (enabled?: boolean) => {
    const axios = useAxios();

    return useQuery({
        queryKey: [API_ROUTES.REFERRERS.KEY],

        queryFn: async () => {
            return (await axios.get<ReferrersCollection>(`${API_ROUTES.REFERRERS.URL}`)).data;
        },
        enabled: enabled,
    });
};
