import React from 'react';
import { Breadcrumb, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ClientDevicesTable } from '@/features/client-mobile/components/ClientDevicesTable.tsx';

export const ClientDevices: React.FC = () => {
    const { t } = useTranslation('breadcrumbs');

    const breadcrumbItems = [
        {
            title: t('home'),
        },
    ];

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Breadcrumb items={breadcrumbItems} />
                </Col>
                <Col span={24} className='flex flex-col gap-y-4'>
                    <span className='text-[20px] leading-6 font-medium'>{t('devices')}</span>
                    <ClientDevicesTable />
                </Col>
            </Row>
        </>
    );
};
