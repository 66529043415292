import React, { useEffect } from 'react';
import { Form, Input, Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { CountryRequest } from '@/types.ts';
import { useNavigate, useParams } from 'react-router-dom';
import { useMessageApiContext } from '@/features/message-api/context';
import { ROUTES } from '@/features/router';
import { useGetDeviceModel, useUpdateDeviceModel } from '@/features/device-models';

export const DeviceModelEditForm: React.FC = () => {
    const { t } = useTranslation('device_models');
    const { id } = useParams();
    const { data, isLoading, isSuccess } = useGetDeviceModel(parseInt(id!));
    const { mutateAsync, isPending } = useUpdateDeviceModel(parseInt(id!));
    const { messageApi } = useMessageApiContext();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    useEffect(() => {
        if (isSuccess) {
            form.setFieldsValue({
                title: data.data.title,
            });
        }
    }, [isSuccess, data, form]);

    const onSubmit = async (values: CountryRequest) => {
        try {
            await mutateAsync({
                title: values.title,
            });
            messageApi.open({
                type: 'success',
                content: t('common:message_success'),
            });
            navigate(ROUTES.DEVICE_MODELS);
        } catch (e) {
            messageApi.open({
                type: 'error',
                content: t('common:message_error'),
            });
        }
    };

    return (
        <Card title={t('edit_device_model')} loading={isLoading}>
            <Form layout="vertical" form={form} onFinish={onSubmit} requiredMark={false}>
                <Form.Item<CountryRequest> name="title" label={t('device_model')} rules={[{ required: true, message: t('common:rule_required') }]}>
                    <Input placeholder={t('device_model')} />
                </Form.Item>
                <Button type="primary" htmlType="submit" loading={isPending}>
                    {t('common:submit')}
                </Button>
                <Button
                    onClick={() => {
                        navigate(ROUTES.DEVICE_MODELS);
                    }}
                    htmlType="button"
                    className="ml-2"
                >
                    {t('common:cancel')}
                </Button>
            </Form>
        </Card>
    );
};
