import { useAxios } from '@/features/axios';
import { useAuthContext } from '@/features/auth';
import { useQuery } from '@tanstack/react-query';
import { API_ROUTES } from '@/api/routes.ts';
import { ClientDeviceListResponse, DeviceData, DeviceEntityClass } from '@/types.ts';

export const useListOfDevices = (page: number, perPage?: number) => {
    const axios = useAxios();
    const { currentUser } = useAuthContext();

    return useQuery({
        queryKey: [API_ROUTES.LIST_OF_DEVICES.KEY, currentUser?.id, page, perPage],

        queryFn: async () => {
            const params = {
                page: page,
                per_page: perPage,
            };

            return (await axios.get<ClientDeviceListResponse>(API_ROUTES.LIST_OF_DEVICES.URL, { params })).data;
        },
    });
};

export const useGetDeviceData = <T extends DeviceData<any>>(id: number, entity_type: DeviceEntityClass) => {
    const axios = useAxios();

    return useQuery({
        queryKey: [API_ROUTES.DEVICE_DATA.KEY, id, entity_type],

        queryFn: async () =>
            (
                await axios.get<T>(`${API_ROUTES.DEVICE_DATA.URL}/${id}`, {
                    params: {
                        entity_class: entity_type,
                    },
                })
            ).data,
    });
};
