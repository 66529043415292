import { useAxios } from '@/features/axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { OsmoDeviceFilter, OsmoDeviceRequest, OsmoDeviceResponseForForm, OsmoDeviceResponseForTable, OsmoDeviceSort } from '@/types.ts';
import { API_ROUTES } from '@/api/routes.ts';
import { getObjectFilledValues } from '@/utils/helper.ts';

export const useOsmoDevices = (page: number, perPage: number, filterBy?: OsmoDeviceFilter, sortBy?: OsmoDeviceSort) => {
    const axios = useAxios();

    return useQuery({
        queryKey: [API_ROUTES.OSMO_DEVICES.KEY, page, perPage, filterBy, sortBy],

        queryFn: async () => {
            const params = {
                filterBy: {} as OsmoDeviceFilter,
                sortBy: {} as OsmoDeviceSort,
                page: page,
                per_page: perPage,
                with: ['user', 'region', 'region.country', 'user.referrer'],
                for: 'table',
            };

            if (filterBy) {
                params.filterBy = getObjectFilledValues(filterBy);
            }

            if (sortBy) {
                params.sortBy = getObjectFilledValues(sortBy);
            }

            return (await axios.get<OsmoDeviceResponseForTable>(`${API_ROUTES.OSMO_DEVICES.URL}`, { params })).data;
        },
    });
};

export const useGetOsmoDevice = (id: number) => {
    const axios = useAxios();

    return useQuery({
        queryKey: [API_ROUTES.OSMO_DEVICES.KEY, id],

        queryFn: async () => {
            const params = {
                with: ['user', 'region', 'region.country', 'edge_cases'],
            };

            return (await axios.get<OsmoDeviceResponseForForm>(`${API_ROUTES.OSMO_DEVICES.URL}/${id}`, { params })).data;
        },
    });
};

export const useUpdateOsmoDevice = (id: number) => {
    const axios = useAxios();

    return useMutation({
        mutationFn: async (device: OsmoDeviceRequest) =>
            (await axios.put<OsmoDeviceResponseForForm>(`${API_ROUTES.OSMO_DEVICES.URL}/${id}`, device)).data,
    });
};

export const useAddOsmoDevice = () => {
    const axios = useAxios();

    return useMutation({
        mutationFn: async (device: OsmoDeviceRequest) => (await axios.post<OsmoDeviceResponseForForm>(`${API_ROUTES.OSMO_DEVICES.URL}`, device)).data,
    });
};

export const useDeleteOsmoDevice = () => {
    const axios = useAxios();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (id: number) => (await axios.delete<OsmoDeviceResponseForForm>(`${API_ROUTES.OSMO_DEVICES.URL}/${id}`)).data,
        onSuccess: async () => await client.invalidateQueries({ queryKey: [API_ROUTES.OSMO_DEVICES.KEY] }),
    });
};

export const useProvideMaintenance = () => {
    const axios = useAxios();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (id: number) => (await axios.post(`${API_ROUTES.OSMO_DEVICE_MAINTENANCE.URL}/${id}`)).data,
        onSuccess: async () => await client.invalidateQueries({ queryKey: [API_ROUTES.OSMO_DEVICES.KEY] }),
    });
};
