import { Device, OsmoDevice } from '@/types.ts';

export const generatePassword = () => {
    let charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let passwordVal = '';
    for (let i = 0, n = charset.length; i < 10; ++i) {
        passwordVal += charset.charAt(Math.random() * n);
    }

    return passwordVal;
};

export const makeDeviceBreadcrumbs = (device?: Device) => {
    return [device?.user?.counterparty, device?.device_model.title, device?.country?.title, device?.region?.title, device?.address]
        .filter((val) => {
            return !!val;
        })
        .join(', ');
};

export const makeArduinoDeviceBreadcrumbs = (device?: OsmoDevice) => {
    return [device?.user?.counterparty, device?.region?.country?.title, device?.region?.title, device?.address]
        .filter((val) => {
            return !!val;
        })
        .join(', ');
};

// prettier-ignore
export function filterObject<T extends object>(
    obj: T,
    predicate: <K extends keyof T>(value: T[K], key: K) => boolean,
) {
    const result: { [K in keyof T]?: T[K] } = {};
    (Object.keys(obj) as Array<keyof T>).forEach((name) => {
        if (predicate(obj[name], name)) {
            result[name] = obj[name];
        }
    });
    return result;
}

export function getObjectFilledValues<T extends object>(obj: T) {
    return filterObject<T>(obj, (value, _) => typeof value != 'undefined' && value != null);
}
