import React from 'react';
import { Card, Col, DatePicker, Flex } from 'antd';
import { useTranslation } from 'react-i18next';
import { FlowAnalyticsFilter } from '@/types.ts';
import { RangePickerProps } from 'antd/lib/date-picker';

type AnalyticsFilterProps = {
    currentValue: FlowAnalyticsFilter;
    handleFilters: (dates?: FlowAnalyticsFilter) => void;
};

export const AnalyticsFilter: React.FC<AnalyticsFilterProps> = ({ currentValue, handleFilters }) => {
    const { RangePicker } = DatePicker;
    const { t } = useTranslation('common');
    const initialValues: RangePickerProps['value'] = [currentValue.date_from, currentValue.date_to];

    const handleChange = (dates: RangePickerProps['value']) => {
        if (dates) {
            const [start, end] = dates;
            handleFilters({
                date_from: start,
                date_to: end,
            });
        } else {
            handleFilters(undefined);
        }
    };

    return (
        <Card className="mb-4">
            <Col xs={{ span: 24 }} md={{ span: 8 }}>
                <Flex gap={8} align="center">
                    <span className="text-[14px]">{t('date')}</span>
                    <RangePicker value={initialValues} onChange={handleChange} className="w-full" />
                </Flex>
            </Col>
        </Card>
    );
};
