import { useAxios } from '@/features/axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { API_ROUTES } from '@/api/routes.ts';
import { RegionRequest, RegionResponse, RegionsTableCollection, RegionsTableFilter, RegionsTableSortBy } from '@/types.ts';

export const useRegions = (page: number, perPage?: number, filterBy?: RegionsTableFilter, sortBy?: RegionsTableSortBy) => {
    const axios = useAxios();

    return useQuery({
        queryKey: [API_ROUTES.REGIONS.KEY, page, perPage, filterBy, sortBy],

        queryFn: async () => {
            let params = {
                filterBy: {} as RegionsTableFilter,
                sortBy: {} as RegionsTableSortBy,
                page: page,
                per_page: perPage,
            };

            if (sortBy?.title) {
                params.sortBy.title = sortBy.title;
            }

            if (sortBy?.country) {
                params.sortBy.country = sortBy.country;
            }

            if (filterBy?.country_id) {
                params.filterBy.country_id = filterBy.country_id;
            }

            return (
                await axios.get<RegionsTableCollection>(`${API_ROUTES.REGIONS.URL}`, {
                    params,
                })
            ).data;
        },
    });
};

export const useAddRegion = () => {
    const axios = useAxios();

    return useMutation({
        mutationFn: async (region: RegionRequest) => {
            return (await axios.post<RegionResponse>(API_ROUTES.REGIONS.URL, region)).data;
        },
    });
};

export const useGetRegion = (id: number) => {
    const axios = useAxios();
    return useQuery({
        queryKey: [API_ROUTES.REGIONS.KEY, id],
        queryFn: async () => {
            return (await axios.get<RegionResponse>(`${API_ROUTES.REGIONS.URL}/${id}`)).data;
        },
    });
};

export const useUpdateRegion = (id: number) => {
    const axios = useAxios();
    return useMutation({
        mutationFn: async (region: RegionRequest) => {
            return (await axios.put<RegionResponse>(`${API_ROUTES.REGIONS.URL}/${id}`, region)).data;
        },
    });
};

export const useDeleteRegion = () => {
    const axios = useAxios();
    const client = useQueryClient();
    return useMutation({
        mutationFn: async (id: number) => {
            return (await axios.delete<RegionResponse>(`${API_ROUTES.REGIONS.URL}/${id}`)).data;
        },
        onSuccess: async () => {
            await client.invalidateQueries({ queryKey: [API_ROUTES.REGIONS.KEY] });
        },
    });
};
