import React from 'react';
import { ConfigProvider, theme } from 'antd';
import { StyleProvider } from '@ant-design/cssinjs';
import ukUa from 'antd/locale/uk_UA';
import enUS from 'antd/es/locale/en_US';
import { useTranslation } from 'react-i18next';

export const locales: { [key: string]: any } = {
    en: enUS,
    uk: ukUa,
};

type AntdProviderProps = React.PropsWithChildren;
export const AntdProvider: React.FC<AntdProviderProps> = ({ children }) => {
    const { i18n } = useTranslation();

    return (
        <ConfigProvider
            locale={locales[i18n.language]}
            theme={{
                algorithm: theme.defaultAlgorithm,
                token: {
                    colorPrimary: '#0050B3',
                    colorLink: '#0050B3',
                    colorBgLayout: '#F4F6F8',
                },
                components: {
                    Select: {
                        optionSelectedBg: 'rgba(0, 0, 0, 0.04)',
                    },
                    Form: {
                        itemMarginBottom: 32,
                    },
                    Modal: {
                        wireframe: true,
                    },
                },
            }}
        >
            <StyleProvider hashPriority="high">{children}</StyleProvider>
        </ConfigProvider>
    );
};
