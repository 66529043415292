import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Space } from 'antd';
import errorImage from '@/assets/ip_error.jpg';

type IpErrorModalProps = {
    isOpen: boolean;
    handleClose: () => void;
};

export const IpErrorModal: React.FC<IpErrorModalProps> = ({ isOpen, handleClose }) => {
    const { t } = useTranslation('devices');

    return <Modal open={isOpen} title={t('common:error')} onCancel={handleClose} destroyOnClose width={840} footer={null} getContainer={false}>
      <div className='flex justify-center items-center flex-col gap-6'>
        <img className="max-w-[253px] max-h-[293px]" src={errorImage} alt={t('common:error')}></img>
        <Space className="flex flex-col text-center">
          <span className='text-2xl'>{t('common:error')}</span>
          <span className='whitespace-pre text-sm text-black text-opacity-45'>{t('ip_error_text')}</span>
        </Space>
        <Button type='primary' onClick={handleClose} htmlType='button'>
          {t('common:close')}
        </Button>
      </div>
    </Modal>
};
