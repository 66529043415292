import { Breadcrumb, Button, Row, Col } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '@/features/router';
import { useTranslation } from 'react-i18next';
import { PlusOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { RegionsTable } from '@/features/regions';

export const Regions = () => {
    const { t } = useTranslation('regions');
    const navigate = useNavigate();

    const breadcrumbItems = [
        {
            title: <Link to={ROUTES.DASHBOARD}>{t('breadcrumbs:home')}</Link>,
        },
        {
            title: t('breadcrumbs:regions'),
        },
    ];

    return (
        <>
            <Row gutter={[10, 10]} className="mb-4 flex-col justify-between md:flex-row gap-y-4">
                <Col xs={{ flex: '1 0 100%' }} md={{ flex: '1 0 auto' }}>
                    <Breadcrumb items={breadcrumbItems} />
                </Col>
                <Col className='flex flex-wrap gap-y-2.5' xs={{ flex: '1 0 100%' }} md={{ flex: '1 0 auto' }}>
                    <Col xs={{ span: 12 }} md={{flex: '1 0 auto'}}>
                        <Button icon={<UnorderedListOutlined />} className='w-full' onClick={() => navigate(ROUTES.COUNTRIES)}>
                            {t('regions:countries')}
                        </Button>
                    </Col>
                    <Col xs={{ span: 12 }} md={{flex: '1 0 auto'}}>
                        <Button type="primary" icon={<PlusOutlined />} className='w-full' onClick={() => navigate(ROUTES.COUNTRY_ADD)}>
                            {t('regions:add_country')}
                        </Button>
                    </Col>
                    <Col xs={{span: 24}} md={{flex: '1 0 auto'}}>
                        <Button type="primary" icon={<PlusOutlined />} className='w-full' onClick={() => navigate(ROUTES.REGION_ADD)}>
                            {t('regions:add_region')}
                        </Button>
                    </Col>
                </Col>
            </Row>
            <RegionsTable />
        </>
    );
};
