export const uk = {
    ua: 'Україна',
    de: 'Германія',
    sk: 'Словаччина',
    selectedCountryAriaLabel: 'Обрана країна',
    noCountrySelected: 'Країна не обрана',
    countryListAriaLabel: 'Список країн',
    searchPlaceholder: 'Пошук',
    zeroSearchResults: 'Нема результатів',
    oneSearchResult: '1 результат знайдено',
    multipleSearchResults: '${count} результатів знайдено',
};
