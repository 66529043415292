import { Button, Checkbox, Form, Input } from 'antd';
import { useAuthContext, useCsrf, useLogin } from '@/features/auth';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { setFormServerErrors } from '@/utils';
import { useProfile } from '@/features/profile';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { ERRORS } from '@/utils/constants.ts';
import { useMessageApiContext } from '@/features/message-api/context';
import { useSetLang } from '@/features/i18n';

const { Title } = Typography;

type LoginFormFields = {
	email: string;
	password: string;
	remember?: boolean;
};

export const LoginForm = () => {
	const { t } = useTranslation('auth');
	const { i18n } = useTranslation();
	const { refetch: getProfile } = useProfile(false);
	const [form] = Form.useForm();
	const { mutateAsync, isPending } = useLogin();
	const { mutateAsync: setLang } = useSetLang();
	const { refetch } = useCsrf();
	const navigate = useNavigate();
	const { setCredentials, setActiveUser } = useAuthContext();
	const { messageApi } = useMessageApiContext();

	const onSubmit = async (values: LoginFormFields) => {
		try {
			await refetch();
			await mutateAsync({
				email: values.email,
				password: values.password,
				remember: values.remember
			});

			await setLang(i18n.language);

			const response = await getProfile();
			setCredentials();
			if (response?.data?.data) {
				setActiveUser(response.data.data);
			}

			return navigate('/');
		} catch (e) {
			if (axios.isAxiosError(e)) {
				let message = t('common:message_error');
				if (e.response?.data.status === ERRORS.AUTHORIZATION_ERROR) {
					message = t('common:message_authorization_error');
				}

				if (e.response?.data.status === ERRORS.VALIDATION_ERROR) {
					const errors = e.response!.data.errors;
					setFormServerErrors(form, errors);
				}

				messageApi.open({
					type: 'error',
					content: message
				});
			}
		}
	};

	return (
		<Form form={form} name="basic" initialValues={{ remember: true }} onFinish={onSubmit} autoComplete="off">
			<Title level={3} className="mb-[38px] text-center">
				{t('auth:login_title')}
			</Title>

			<Form.Item<LoginFormFields>
				name="email"
				rules={[
					{
						required: true,
						message: t('auth:email_required_error')
					},
					{
						type: 'email',
						message: t('auth:email_valid_error')
					}
				]}
				className={'mb-[22px]'}
			>
				<Input size="large" prefix={<MailOutlined />} placeholder={t('auth:email')} />
			</Form.Item>

			<Form.Item<LoginFormFields>
				name="password"
				rules={[{ required: true, message: t('auth:password_required_error') }]}
				className={'mb-[22px]'}
			>
				<Input.Password size="large" prefix={<LockOutlined />} placeholder={t('auth:password')} />
			</Form.Item>

			<Form.Item<LoginFormFields> name="remember" valuePropName="checked" className={'mb-[22px]'}>
				<Checkbox>{t('auth:remember')}</Checkbox>
			</Form.Item>

			<Form.Item>
				<Button block loading={isPending} type="primary" htmlType="submit" size={'large'}>
					{t('auth:submit')}
				</Button>
			</Form.Item>
		</Form>
	);
};
