import React from 'react';
import { Outlet } from 'react-router-dom';
import { Card } from 'antd';
import imageBg from '@/assets/water-bg.jpg';
import { AuthHeader } from '@/layouts/components/AuthHeader.tsx';

interface AuthLayoutProps extends React.PropsWithChildren {}

export const AuthLayout: React.FC<AuthLayoutProps> = () => {
    return (
        <div style={{ backgroundImage: `url(${imageBg})` }} className="bg-no-repeat bg-center bg-cover min-h-screen flex pt-24 pb-20">
            <AuthHeader />

            <div className="!flex justify-center m-auto">
                <Card className="!w-[355px] md:!w-[373px] rounded-[23px] px-1.5 pt-10 pb-1.5" bordered={false}>
                    <Outlet />
                </Card>
            </div>
        </div>
    );
};
