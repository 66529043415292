import React, { useCallback, useEffect, useState } from 'react';
import { AuthContext } from './Context.ts';
import { IS_AUTH_LOCAL_STORAGE_KEY, USER_LOCAL_STORAGE_KEY } from './../constants.ts';
import { User } from '@/types.ts';
import { useUserLocaleContext } from '@/features/i18n';

interface AuthContextProviderProps extends React.PropsWithChildren {}
export const AuthContextProvider: React.FC<AuthContextProviderProps> = ({ children }) => {
    const isAuthFromLocalStorage = localStorage.getItem(IS_AUTH_LOCAL_STORAGE_KEY);
    const userFromLocalStorage = localStorage.getItem(USER_LOCAL_STORAGE_KEY);
    const { setUserLocale } = useUserLocaleContext();

    const [isAuth, setIsAuth] = useState<boolean>(!!isAuthFromLocalStorage);
    const [currentUser, setCurrentUser] = useState(userFromLocalStorage ? JSON.parse(userFromLocalStorage) : null);

    useEffect(() => {
        if (currentUser) {
            setUserLocale(currentUser.language);
        }
    }, [currentUser]);

    const setCredentials = useCallback(() => {
        setIsAuth(true);
        localStorage.setItem(IS_AUTH_LOCAL_STORAGE_KEY, 'true');
    }, [isAuth]);

    const removeCredentials = useCallback(() => {
        localStorage.removeItem(IS_AUTH_LOCAL_STORAGE_KEY);
        setIsAuth(false);
        localStorage.removeItem(USER_LOCAL_STORAGE_KEY);
        setCurrentUser(null);
    }, []);

    const setActiveUser = useCallback(
        (user: User) => {
            setCurrentUser(user);
            localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(user));
        },
        [currentUser]
    );

    return <AuthContext.Provider value={{ isAuth, setCredentials, removeCredentials, currentUser, setActiveUser }}>{children}</AuthContext.Provider>;
};
