import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Select, Card, Flex, Tooltip, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { UserAddModal, useUsers } from '@/features/users';
import { DeviceRequest, UserRequest, UserResponse } from '@/types.ts';
import { useNavigate, useParams } from 'react-router-dom';
import { useMessageApiContext } from '@/features/message-api/context';
import { ROUTES } from '@/features/router';
import { ControlOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { useRegions } from '@/features/regions';
import axios from 'axios';
import { generatePassword, setFormServerErrors } from '@/utils';
import { useCountries } from '@/features/countries';
import { useGetDevice, useTestDevice, useUpdateDevice } from '@/features/devices';
import { useDeviceModels } from '@/features/device-models';
import { SUCCESS_RESPONSE } from '@/utils/constants.ts';
import { MikrotikSettingsModal } from '@/features/devices/component/MikrotikSettingsModal.tsx';
import { WireguardIcon } from '@/features/devices/component/WireguardIcon.tsx';
import { useRBAC } from '@/features/auth';

export const DeviceEditForm: React.FC = () => {
    const { t } = useTranslation('devices');
    const { id } = useParams();
    const { isAdmin } = useRBAC();
    const [form] = Form.useForm();
    const countryValue = Form.useWatch('country_id', form);
    const { mutateAsync: testDevice } = useTestDevice();
    const { data: users, refetch: refetchUsers } = useUsers(1, 100);
    const { data: deviceModels } = useDeviceModels(1, 100);
    const { data: countries } = useCountries(1, 100);
    const { data: regions } = useRegions(1, 100, { country_id: countryValue });
    const { data, isLoading, isSuccess } = useGetDevice(parseInt(id!));
    const { mutateAsync, isPending } = useUpdateDevice(parseInt(id!));
    const { messageApi } = useMessageApiContext();
    const navigate = useNavigate();
    const [openAddUserModel, setOpenAddUserModel] = useState(false);
    const [openMikrotikSettingsModal, setMikrotikSettingsModal] = useState(false);
    const [wireguardActive, setWireguardActive] = useState(false);
    const deviceCredentials = Form.useWatch((values) => values.uri && values.login && values.password && values.pin, form);

    useEffect(() => {
        if (isSuccess) {
            form.setFieldsValue({
                user_id: data.data.user.id,
                device_model_id: data.data.device_model.id,
                uri: data.data.uri,
                login: data.data.login,
                password: data.data.password,
                pin: data.data.pin,
                serial_number: data.data.serial_number,
                country_id: data.data.country.id,
                region_id: data.data.region.id,
                address: data.data.address,
                auth_key: data.data.auth_key,
                tenant_key: data.data.tenant_key,
                ip: data.data.ip,
                wireguard_public: data.data.wireguard_public,
            });
            setWireguardActive(data.data.wireguard_active);
        }
    }, [isSuccess, data, form]);

    const onSubmit = async (values: DeviceRequest) => {
        try {
            let params: DeviceRequest = {
                user_id: values.user_id,
                device_model_id: values.device_model_id,
                uri: values.uri,
                serial_number: values.serial_number,
                login: values.login,
                password: values.password,
                pin: values.pin,
                country_id: values.country_id,
                region_id: values.region_id,
                address: values.address,
                auth_key: values.auth_key,
                tenant_key: values.tenant_key,
                ip: values.ip,
                wireguard_public: values.wireguard_public,
            };

            await mutateAsync(params);
            messageApi.open({
                type: 'success',
                content: t('common:message_success'),
            });
            navigate(ROUTES.DEVICES);
        } catch (e) {
            messageApi.open({
                type: 'error',
                content: t('common:message_error'),
            });

            if (axios.isAxiosError(e)) {
                const errors = e.response!.data.errors;
                errors && setFormServerErrors(form, errors);
            }
        }
    };

    const onCountryChange = () => {
        form.setFieldValue('region_id', null);
    };

    const onTest = async () => {
        try {
            const response = await testDevice({
                uri: form.getFieldValue('uri'),
                login: form.getFieldValue('login'),
                password: form.getFieldValue('password'),
                pin: form.getFieldValue('pin'),
            });

            if (response.status === SUCCESS_RESPONSE) {
                messageApi.open({
                    type: 'success',
                    content: t('successful_test'),
                });
            }
        } catch (e) {
            messageApi.open({
                type: 'error',
                content: t('failed_test'),
            });
        }
    };

    const handleClose = () => {
        setOpenAddUserModel(false);
    };

    const handleGenerateTenant = () => {
        form.setFieldValue('tenant_key', generatePassword());
    };

    const handleSubmitNewUser = (user?: UserResponse) => {
        if (user) {
            refetchUsers();
            form.setFieldValue('user_id', user.data.id);
        }
    };

    return (
        <Card title={t('edit_device')} loading={isLoading}>
            <Form className="flex flex-col gap-y-[24px]" layout="vertical" form={form} onFinish={onSubmit} requiredMark={false}>
                <Col xs={{ span: 24 }} md={{ span: 12 }} className="flex flex-col gap-y-6 md:flex-row md:items-end md:gap-x-4">
                    <Form.Item<DeviceRequest>
                        name="user_id"
                        label={t('counterparty')}
                        rules={[{ required: true, message: t('common:rule_required') }]}
                        className={'w-full mb-0'}
                    >
                        <Select
                            placeholder={t('counterparty')}
                            options={users?.data.paginated}
                            fieldNames={{ value: 'id', label: 'counterparty' }}
                            showSearch
                            optionFilterProp={'counterparty'}
                        ></Select>
                    </Form.Item>

                    <Button
                        className="w-max"
                        htmlType={'button'}
                        type="primary"
                        size={'middle'}
                        icon={<PlusOutlined />}
                        onClick={() => setOpenAddUserModel(true)}
                    >
                        {t('add_user')}
                    </Button>
                </Col>
                {(isAdmin() && (
                    <>
                        <div className="grid grid-flow-row gap-x-4 gap-y-6 md:grid-flow-col">
                            <Form.Item<DeviceRequest>
                                name="device_model_id"
                                label={t('device')}
                                rules={[{ required: true, message: t('common:rule_required') }]}
                                className="mb-0"
                            >
                                <Select
                                    placeholder={t('device')}
                                    options={deviceModels?.data.paginated}
                                    fieldNames={{ value: 'id', label: 'title' }}
                                    showSearch
                                    optionFilterProp={'title'}
                                ></Select>
                            </Form.Item>
                            <Form.Item<DeviceRequest>
                                name="uri"
                                label={t('uri')}
                                rules={[{ required: true, message: t('common:rule_required') }]}
                                className="mb-0"
                            >
                                <Input disabled placeholder={t('uri')} />
                            </Form.Item>
                            <Form.Item<DeviceRequest>
                                name="ip"
                                label={t('ip')}
                                rules={[{ required: true, message: t('common:rule_required') }]}
                                className="mb-0"
                            >
                                <Input disabled placeholder={t('ip')} />
                            </Form.Item>
                            <Form.Item<DeviceRequest>
                                name="serial_number"
                                label={t('serial_number')}
                                rules={[{ required: true, message: t('common:rule_required') }]}
                                className="mb-0"
                            >
                                <Input placeholder={t('serial_number')} />
                            </Form.Item>
                        </div>
                        <Flex className="flex-col md:flex-row" gap="middle" align={'center'}>
                            <Form.Item<DeviceRequest>
                                name="login"
                                label={t('login')}
                                rules={[{ required: true, message: t('common:rule_required') }]}
                                className={'w-full mb-0'}
                            >
                                <Input placeholder={t('login')} />
                            </Form.Item>
                            <Form.Item<DeviceRequest>
                                name="password"
                                label={t('password')}
                                rules={[{ required: true, message: t('common:rule_required') }]}
                                className={'w-full mb-0'}
                            >
                                <Input placeholder={t('password')} />
                            </Form.Item>
                            <div className="flex w-full items-end gap-x-2">
                                <Form.Item<DeviceRequest>
                                    name="pin"
                                    label={t('pin')}
                                    rules={[{ required: true, message: t('common:rule_required') }]}
                                    className={'w-full mb-0'}
                                >
                                    <Input placeholder={t('pin')} />
                                </Form.Item>
                                <Button htmlType={'button'} type={'primary'} icon={<SyncOutlined />} onClick={onTest} disabled={!deviceCredentials}>
                                    {t('test')}
                                </Button>
                            </div>
                        </Flex>
                        <div className="grid grid-flow-row gap-y-6 md:grid-flow-col gap-x-4">
                            <Form.Item<UserRequest>
                                name="country_id"
                                label={t('country')}
                                rules={[{ required: true, message: t('common:rule_required') }]}
                                className="mb-0"
                            >
                                <Select
                                    placeholder={t('country')}
                                    options={countries?.data.paginated}
                                    fieldNames={{ value: 'id', label: 'title' }}
                                    showSearch
                                    optionFilterProp={'title'}
                                    onChange={onCountryChange}
                                ></Select>
                            </Form.Item>
                            <Form.Item<DeviceRequest>
                                name="region_id"
                                label={t('region')}
                                rules={[{ required: true, message: t('common:rule_required') }]}
                                className="mb-0"
                                shouldUpdate
                            >
                                <Select
                                    placeholder={t('region')}
                                    options={regions?.data.paginated}
                                    fieldNames={{ value: 'id', label: 'title' }}
                                    showSearch
                                    optionFilterProp={'title'}
                                    disabled={!countryValue}
                                ></Select>
                            </Form.Item>
                            <Form.Item<DeviceRequest> name="address" label={t('address')} className="mb-0">
                                <Input placeholder={t('address')} />
                            </Form.Item>
                        </div>
                        <div className="grid grid-flow-row gap-y-6 md:grid-flow-col gap-x-4">
                            <Form.Item<DeviceRequest> name="auth_key" label={t('auth_key')} className="mb-0">
                                <Input placeholder={t('auth_key')} />
                            </Form.Item>
                            <Form.Item<DeviceRequest> name="tenant_key" label={t('tenant_key')} className="mb-0">
                                <Input
                                    placeholder={t('tenant_key')}
                                    addonAfter={
                                        <Tooltip placement="top" title={t('generate_tenant')}>
                                            <ControlOutlined onClick={handleGenerateTenant} />
                                        </Tooltip>
                                    }
                                />
                            </Form.Item>
                            <Flex gap={10} className="items-end">
                                <Form.Item<DeviceRequest> className="w-full mb-0" name="wireguard_public" label={t('wireguard_public')}>
                                    <Input placeholder={t('wireguard_public')}></Input>
                                </Form.Item>
                                <WireguardIcon enabled={wireguardActive} />
                            </Flex>
                        </div>
                    </>
                )) || (
                    <>
                        <div className="grid grid-flow-row gap-y-6 md:grid-cols-4 gap-x-4">
                            <Form.Item<DeviceRequest>
                                name="device_model_id"
                                label={t('device')}
                                rules={[{ required: true, message: t('common:rule_required') }]}
                                className="mb-0"
                            >
                                <Select
                                    placeholder={t('device')}
                                    options={deviceModels?.data.paginated}
                                    fieldNames={{ value: 'id', label: 'title' }}
                                    showSearch
                                    optionFilterProp={'title'}
                                ></Select>
                            </Form.Item>
                            <Form.Item<UserRequest>
                                name="country_id"
                                label={t('country')}
                                rules={[{ required: true, message: t('common:rule_required') }]}
                                className="mb-0"
                            >
                                <Select
                                    placeholder={t('country')}
                                    options={countries?.data.paginated}
                                    fieldNames={{ value: 'id', label: 'title' }}
                                    showSearch
                                    optionFilterProp={'title'}
                                    onChange={onCountryChange}
                                ></Select>
                            </Form.Item>
                            <Form.Item<DeviceRequest>
                                name="region_id"
                                label={t('region')}
                                rules={[{ required: true, message: t('common:rule_required') }]}
                                className="mb-0"
                                shouldUpdate
                            >
                                <Select
                                    placeholder={t('region')}
                                    options={regions?.data.paginated}
                                    fieldNames={{ value: 'id', label: 'title' }}
                                    showSearch
                                    optionFilterProp={'title'}
                                    disabled={!countryValue}
                                ></Select>
                            </Form.Item>
                            <Form.Item<DeviceRequest> name="address" label={t('address')} className="mb-0">
                                <Input placeholder={t('address')} />
                            </Form.Item>
                        </div>
                    </>
                )}

                <div className="flex flex-col-reverse gap-y-2.5 justify-between md:flex-row">
                    <div className="flex gap-2">
                        <Button type="primary" className='w-full' htmlType="submit" loading={isPending}>
                            {t('common:submit')}
                        </Button>
                        <Button
                            onClick={() => {
                                navigate(ROUTES.DASHBOARD);
                            }}
                            htmlType="button"
                            className="w-full"
                        >
                            {t('common:cancel')}
                        </Button>
                    </div>
                    {isAdmin() && (
                        <Button type="primary" htmlType="button" onClick={() => setMikrotikSettingsModal(true)}>
                            {t('mikrotik_settings')}
                        </Button>
                    )}
                </div>
            </Form>

            <UserAddModal isOpen={openAddUserModel} handleClose={handleClose} handleSubmit={handleSubmitNewUser} />
            <MikrotikSettingsModal deviceId={parseInt(id!)} handleClose={() => setMikrotikSettingsModal(false)} isOpen={openMikrotikSettingsModal} />
        </Card>
    );
};
