import React from 'react';
import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { CustomHeader } from '@/layouts/components/CustomHeader.tsx';

interface DefaultLayoutProps extends React.PropsWithChildren {
}

const { Content } = Layout;

export const DefaultLayout: React.FC<DefaultLayoutProps> = () => {
	/*    const {
		token: { colorBgContainer, borderRadiusLG },
	} = theme.useToken();*/

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<CustomHeader />
			<Content className={'pt-[15px] px-[19px] pb-[25px]'}>
				<Outlet />

				{/*<div
                    className="px-[19px] pb-[25px] pt-[15px]"
                    style={{
                        minHeight: 360,
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                    }}
                >
                    <Outlet />
                </div>*/}
			</Content>
		</Layout>
	);
};
