import { useAxios } from '@/features/axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { LoginRequest, LoginResponse } from '@/types.ts';
import { API_ROUTES } from '@/api/routes.ts';

export const useCsrf = () => {
    const axios = useAxios();

    return useQuery({
        queryKey: ['csrf'],
        queryFn: async () => {
            return (await axios.get('/sanctum/csrf-cookie')).data;
        },
        enabled: false,
        retry: false,
    });
};

export const useLogin = () => {
    const axios = useAxios();

    return useMutation({
        mutationFn: async (credentials: LoginRequest) => {
            return (await axios.post<LoginResponse>(API_ROUTES.AUTH_LOGIN.URL, credentials)).data;
        },
    });
};

export const useLogout = () => {
    const axios = useAxios();
    const client = useQueryClient();

    return useMutation({
        mutationFn: async (credentials) => {
            return await axios.post<LoginResponse>(API_ROUTES.AUTH_LOGOUT.URL, credentials);
        },
        onSuccess: async () => {
            await client.resetQueries();
        },
    });
};
