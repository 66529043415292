import React, {useState} from 'react';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';

interface ReactQueryProviderProps extends React.PropsWithChildren {}
export const ReactQueryProvider: React.FC<ReactQueryProviderProps> = ({children}) => {
	const [client] = useState(() => new QueryClient());
	return (
		<QueryClientProvider client={client}>
			{children}
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	);
};