import { useState, useMemo } from 'react';
import { OsmoDeviceFilter, OsmoDeviceForTable, OsmoDeviceSort, SortOrder } from '@/types.ts';
import { Badge, Card, Divider, Flex, type TableProps, Tooltip } from 'antd';
import { OsmoDevicesFilter } from '@/features/osmo_devices/component/OsmoDevicesFilter.tsx';
import { useTranslation } from 'react-i18next';
import { useDeleteOsmoDevice, useOsmoDevices } from '@/features/osmo_devices/hooks/api.ts';
import { TableLocale } from 'antd/es/table/interface';
import { TFunction } from 'i18next';
import { DeleteOutlined, EditOutlined, SettingOutlined, WarningOutlined } from '@ant-design/icons';
import { ROUTES } from '@/features/router';
import { useNavigate } from 'react-router-dom';
import { ConfirmDeleteModal } from '@/features/common/component/ConfirmDeleteModal.tsx';
import { osmoDeviceAnalytics } from '@/features/osmo_devices/constants.ts';
import { StatusBadge } from '@/features/common/component/StatusBadge.tsx';
import { useRBAC } from '@/features/auth';
import { AdaptiveTable } from '@/features/adaptive/components/AdaptiveTable.tsx';

const generateOsmoSettingsColumns = (t: TFunction<string, undefined>) => {
    let result = [];

    for (const key of osmoDeviceAnalytics) {
        result.push({
            title: t(`osmo_devices:${key}`),
            dataIndex: key,
            key: key,
            sorter: true,
        });
    }

    return result;
};

export const OsmoDevicesTable = () => {
    const [page, setPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [filter, setFilter] = useState<OsmoDeviceFilter>();
    const [sortBy, setSortBy] = useState<OsmoDeviceSort>();
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [deleteModalID, setDeleteModalID] = useState<number>(0);
    const { mutateAsync } = useDeleteOsmoDevice();

    const { data, isLoading } = useOsmoDevices(page, perPage, filter, sortBy);

    const { t } = useTranslation('devices');
    const navigate = useNavigate();
    const { isClient } = useRBAC();

    const tableLocale: TableLocale = {
        emptyText: <div className={'py-[74px]'}> {t('common:emptyText')}</div>,
    };

    const filterChanged = (values: OsmoDeviceFilter) => {
        setPage(1);
        setFilter(values);
    };

    const handleTableChange: TableProps<OsmoDeviceForTable>['onChange'] = (pagination, _filters, sorter) => {
        if (pagination?.current) {
            setPage(pagination.current);
        }

        if (pagination?.pageSize) {
            setPerPage(pagination.pageSize);
        }

        if (!Array.isArray(sorter) && sorter.columnKey !== undefined) {
            let sortOrder: SortOrder;
            if (sorter.order === 'descend') {
                sortOrder = 'desc';
            } else if (sorter.order === 'ascend') {
                sortOrder = 'asc';
            }

            const key: keyof OsmoDeviceSort = sorter.columnKey as keyof OsmoDeviceSort;

            setSortBy((prevState) => {
                const newState = { ...prevState };
                newState[key] = sortOrder;
                return newState;
            });
        }
    };

    const columns = useMemo<TableProps<OsmoDeviceForTable>['columns']>(
        () => [
            {
                title: t('user'),
                dataIndex: ['user', 'counterparty'],
                key: 'user',
                render: (text: string) => <a>{text}</a>,
                fixed: 'left',
            },
            {
                title: t('state'),
                key: 'state',
                dataIndex: 'state',
                render: (_, { state }) => (state ? <Badge status="success" text={t('online')} /> : <Badge status="error" text={t('offline')} />),
                fixed: 'left',
            },
            {
                title: t('common:status'),
                key: 'status',
                dataIndex: 'status',
                render: (_, { status }) => <StatusBadge status={status} />,
                fixed: 'left',
            },
            {
                title: t('serial_number'),
                key: 'serial_number',
                dataIndex: 'serial_number',
            },
            {
                title: t('country'),
                dataIndex: ['region', 'country', 'title'],
                key: 'country',
                sorter: true,
            },
            {
                title: t('region'),
                dataIndex: ['region', 'title'],
                key: 'region',
                sorter: true,
            },
            {
                title: t('address'),
                dataIndex: 'address',
                key: 'address',
                sorter: true,
            },
            ...generateOsmoSettingsColumns(t),
            {
                title: t('dealer'),
                dataIndex: ['user', 'referrer', 'counterparty'],
                key: 'diller',
                sorter: true,
            },
            {
                title: t('common:actions'),
                key: 'action',
                render: (_, record) => (
                    <Flex align="center">
                        <Tooltip placement="top" title={t('accidents')}>
                            <WarningOutlined
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(`${ROUTES.OSMO_DEVICES_ACCIDENTS}/${record.id}`);
                                }}
                                style={{ color: '#c2980f', fontSize: 'clamp(1rem, 5vw, 20px)' }}
                            />
                        </Tooltip>
                        {!isClient() && (
                            <>
                                <Divider type="vertical" />
                                <Tooltip placement="top" title={t('notification_settings')}>
                                    <SettingOutlined
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(`${ROUTES.OSMO_DEVICES_NOTIFICATIONS}/${record.id}`);
                                        }}
                                        style={{ color: '#0c2cc7', fontSize: 'clamp(1rem, 5vw, 20px)' }}
                                    />
                                </Tooltip>
                                <Divider type="vertical" />
                                <Tooltip placement="top" title={t('common:tooltip_edit')}>
                                    <EditOutlined
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(`${ROUTES.OSMO_DEVICES_EDIT}/${record.id}`);
                                        }}
                                        style={{ color: '#389E0D', fontSize: 'clamp(1rem, 5vw, 20px)' }}
                                    />
                                </Tooltip>
                                <Divider type="vertical" />
                                <Tooltip placement="top" title={t('common:tooltip_delete')}>
                                    <DeleteOutlined
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setDeleteModalID(record.id);
                                            setDeleteModal(true);
                                        }}
                                        style={{ color: '#CF1322', fontSize: 'clamp(1rem, 5vw, 20px)' }}
                                    />
                                </Tooltip>
                            </>
                        )}
                    </Flex>
                ),
                fixed: 'right',
            },
        ],
        []
    );

    return (
        <>
            <OsmoDevicesFilter setValues={filterChanged} />
            <Card bordered={false} className={'shadow-none'}>
                <AdaptiveTable
                    loading={isLoading}
                    columns={columns}
                    dataSource={data?.data.paginated}
                    onChange={handleTableChange}
                    rowKey="id"
                    scroll={{ x: 'max-content' }}
                    pagination={{
                        current: page,
                        total: data?.data.meta?.total,
                        defaultPageSize: perPage,
                        showSizeChanger: true,
                        showTotal: (total, range) =>
                            t('common:paginationTotal', {
                                range1: range[0],
                                range2: range[1],
                                total: total,
                            }),
                    }}
                    locale={tableLocale}
                    collapseLabel={(record) => (
                        <Flex justify="space-between" align="center">
                            <span>{t('user')}</span>
                            <span>{record.user.counterparty}</span>
                        </Flex>
                    )}
                    omitColumns={['user']}
                />
            </Card>
            <ConfirmDeleteModal
                setOpened={setDeleteModal}
                opened={deleteModal}
                title={t('delete_confirmation')}
                mutateAsync={mutateAsync}
                id={deleteModalID}
            />
        </>
    );
};
