import logo from '@/assets/logo.svg';
import React from 'react';
import { LangSwitcher } from '@/features/i18n/components';
import { Layout } from 'antd';
const { Header } = Layout;

type AuthHeaderProps = {};

export const AuthHeader: React.FC<AuthHeaderProps> = () => {
    return (
        <Header className={'flex items-center justify-between bg-white absolute top-0 left-0 right-0 px-[15px] md:px-[30px] h-[50px] md:h-[64px]'}>
            <img src={logo} className="max-w-[147px] logo cursor-pointer" alt="Akvantis" />

            <LangSwitcher />
        </Header>
    );
};
