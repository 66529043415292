import { Card, Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { DevicesTableFilter } from '@/types.ts';
import { useCountries } from '@/features/countries';
import { useDeviceModels } from '@/features/device-models';

type DevicesFilterProps = {
    handleSearch: (values?: DevicesTableFilter) => void;
};

export const DevicesFilter: React.FC<DevicesFilterProps> = ({ handleSearch }) => {
    const { data: countries } = useCountries(1, 100);
    const { data: deviceModels } = useDeviceModels(1, 100);
    const { t } = useTranslation('devices');

    return (
        <Card className={'mb-4'}>
            <Form
                className={'grid grid-flow-row md:grid-flow-col gap-4'}
                onValuesChange={(_, values) => {
                    handleSearch(values);
                }}
                labelCol={{ xs: { flex: '1 0 25%' }, md: { flex: '1 0 auto' } }}
                wrapperCol={{ xs: { flex: '1 0 75%' }, md: { flex: '1 0 auto' } }}
            >
                <Form.Item<DevicesTableFilter> name="country_id" label={t('country')} className={'mb-0'}>
                    <Select
                        placeholder={t('select_country')}
                        options={countries?.data.paginated}
                        fieldNames={{ value: 'id', label: 'title' }}
                        showSearch
                        allowClear={true}
                        optionFilterProp={'title'}
                    ></Select>
                </Form.Item>
                <Form.Item<DevicesTableFilter> name="state" label={t('state')} className={'mb-0'}>
                    <Select
                        placeholder={t('select_state')}
                        options={[
                            { value: 1, label: t('online') },
                            { value: 0, label: t('offline') },
                        ]}
                        allowClear={true}
                    ></Select>
                </Form.Item>
                <Form.Item<DevicesTableFilter> name="device_model_id" label={t('system')} className={'mb-0'}>
                    <Select
                        placeholder={t('select_system')}
                        options={deviceModels?.data.paginated}
                        fieldNames={{ value: 'id', label: 'title' }}
                        showSearch
                        allowClear={true}
                        optionFilterProp={'title'}
                    ></Select>
                </Form.Item>
                <Form.Item<DevicesTableFilter> name="deleted" label={t('deleted')} className={'mb-0'}>
                    <Select
                        placeholder={t('no')}
                        options={[
                            { value: 'no', label: t('no') },
                            { value: 'yes', label: t('yes') },
                            { value: 'all', label: t('all') },
                        ]}
                        allowClear={true}
                    ></Select>
                </Form.Item>
            </Form>
        </Card>
    );
};
