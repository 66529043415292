import { Breadcrumb, Button } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '@/features/router';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { DeviceModelsTable } from '@/features/device-models';

export const DeviceModels = () => {
    const { t } = useTranslation('device_models');
    const navigate = useNavigate();

    const breadcrumbItems = [
        {
            title: <Link to={ROUTES.DASHBOARD}>{t('breadcrumbs:home')}</Link>,
        },
        {
            title: t('breadcrumbs:device_models'),
        },
    ];

    return (
        <>
            <div className="flex flex-col md:flex-row justify-between mb-4 gap-y-2.5">
                <Breadcrumb items={breadcrumbItems} />

                <Button type="primary" className='w-max' icon={<PlusOutlined />} onClick={() => navigate(ROUTES.DEVICE_MODEL_ADD)}>
                    {t('device_models:add_device_model')}
                </Button>
            </div>
            <DeviceModelsTable />
        </>
    );
};
