export const ROUTES = {
    DASHBOARD: '/',
    AUTH_LOGIN: '/auth',
    PROFILE: '/profile',
    USERS: '/users',
    USER_EDIT: '/users/edit',
    USER_ADD: '/users/add',
    COUNTRIES: '/countries',
    COUNTRY_EDIT: '/countries/edit',
    COUNTRY_ADD: '/countries/add',
    REGIONS: '/regions',
    REGION_EDIT: '/regions/edit',
    REGION_ADD: '/regions/add',
    DEVICE_MODELS: '/device-models',
    DEVICE_MODEL_EDIT: '/device-models/edit',
    DEVICE_MODEL_ADD: '/device-models/add',
    DEVICES: '/devices',
    DEVICES_VIEW: '/devices',
    DEVICE_EDIT: '/devices/edit',
    DEVICE_ADD: '/devices/add',
    DEVICE_ACCIDENTS: '/devices/accidents',
    DEVICE_NOTIFICATIONS: '/devices/notifications',
    ANALYTICS_VIEW: '/analytics/view',
    DEVICE_IFRAME: '/devices/iframe',
    OSMO_DEVICES: '/devices-osmo',
    OSMO_DEVICES_VIEW: '/devices-osmo',
    OSMO_DEVICES_ADD: '/devices-osmo/add',
    OSMO_DEVICES_EDIT: '/devices-osmo/edit',
    OSMO_DEVICES_NOTIFICATIONS: '/devices-osmo/notifications',
    OSMO_DEVICES_ACCIDENTS: '/devices-osmo/accidents',
};
