import { Button, Card, Form, Input, Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAddUser, useReferrers, useRoles } from '@/features/users';
import { useMessageApiContext } from '@/features/message-api/context';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/features/router';
import { generatePassword, setFormServerErrors } from '@/utils';
import { UserRequest } from '@/types.ts';
import { useRegions } from '@/features/regions';
import { ControlOutlined } from '@ant-design/icons';
import { useRBAC } from '@/features/auth';
import { useEffect, useState } from 'react';
import { ROLES } from '@/features/auth/constants.ts';
import { useCountries } from '@/features/countries';
import IntlTelInput from 'intl-tel-input/reactWithUtils';
import 'intl-tel-input/build/css/intlTelInput.css';
import { initOptions } from '@/features/iti/config.ts';

export const UserAddForm = () => {
    const { isDiller, isAdmin } = useRBAC();
    const [form] = Form.useForm();
    const { i18n } = useTranslation();
    const countryValue = Form.useWatch('country_id', form);
    const { mutateAsync, isPending } = useAddUser();
    const { data: roles } = useRoles();
    const { data: referrers } = useReferrers(isAdmin());
    const { data: countries } = useCountries(1, 100);
    const { data: regions } = useRegions(1, 100, { country_id: countryValue });
    const { t } = useTranslation('users');
    const { messageApi } = useMessageApiContext();
    const navigate = useNavigate();
    const [phone, setPhone] = useState<string | null>(null);
    const [isValidPhone, setIsValidPhone] = useState<boolean | null>(null);

    useEffect(() => {
        if (isDiller()) {
            form.setFieldValue('role', ROLES.USER);
        }
    }, [isDiller, form]);

    const onSubmit = async (values: UserRequest) => {
        try {
            let params: UserRequest = {
                counterparty: values.counterparty,
                role: values.role,
                phone: values.phone,
                email: values.email,
                password: values.password,
                country_id: values.country_id,
                region_id: values.region_id,
                address: values.address,
            };

            if (isAdmin()) {
                params.referrer_id = values.referrer_id;
            }

            await mutateAsync(params);
            messageApi.open({
                type: 'success',
                content: t('common:message_success'),
            });
            navigate(ROUTES.USERS);
        } catch (e) {
            messageApi.open({
                type: 'error',
                content: t('common:message_error'),
            });
            if (axios.isAxiosError(e)) {
                const errors = e.response!.data.errors;
                setFormServerErrors(form, errors);
            }
        }
    };

    useEffect(() => {
        form.setFieldValue('phone', phone);
    }, [phone, form]);

    const handleGeneratePassword = () => {
        form.setFieldValue('password', generatePassword());
    };

    const onCountryChange = () => {
        form.setFieldValue('region_id', null);
    };

    return (
        <Card title={t('add_user')}>
            <Form form={form} name="form_add_user" layout="vertical" onFinish={onSubmit} requiredMark={false}>
                <div className="grid grid-flow-row md:grid-cols-3 gap-x-4">
                    <Form.Item<UserRequest>
                        name="counterparty"
                        label={t('counterparty')}
                        rules={[{ required: true, message: t('common:rule_required') }]}
                    >
                        <Input placeholder={t('counterparty')} />
                    </Form.Item>
                    <Form.Item<UserRequest> name="role" label={t('role')} rules={[{ required: true, message: t('common:rule_required') }]}>
                        <Select placeholder={t('role')} disabled={isDiller()}>
                            {roles?.map((item) => (
                                <Select.Option title={item} key={item} id={item} value={item}>
                                    {t(item, { ns: 'common' })}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {isAdmin() && (
                        <Form.Item<UserRequest> name="referrer_id" label={t('referrer')}>
                            <Select
                                placeholder={t('referrer')}
                                options={referrers?.data}
                                fieldNames={{ value: 'id', label: 'counterparty' }}
                                showSearch
                                optionFilterProp={'counterparty'}
                            ></Select>
                        </Form.Item>
                    )}

                    <Form.Item<UserRequest>
                        name="phone"
                        label={t('phone')}
                        rules={[
                            {
                                required: true,
                                message: t('common:rule_required'),
                            },
                            {
                                message: t('common:rule_invalid_number'),
                                validator: (_, value) => {
                                    if ((value && isValidPhone) || !value) {
                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject();
                                    }
                                },
                            },
                        ]}
                    >
                        <div key={i18n.language}>
                            <IntlTelInput onChangeNumber={setPhone} onChangeValidity={setIsValidPhone} initOptions={initOptions(i18n)} />
                        </div>
                    </Form.Item>
                    <Form.Item<UserRequest>
                        name="email"
                        label={t('email')}
                        rules={[
                            { required: true, message: t('common:rule_required') },
                            { type: 'email', message: t('rules_email_valid') },
                        ]}
                    >
                        <Input placeholder={t('email')} />
                    </Form.Item>
                    <Form.Item<UserRequest> name="password" label={t('password')} rules={[{ required: true, message: t('common:rule_required') }]}>
                        <Input
                            placeholder={t('password')}
                            addonAfter={
                                <Tooltip placement="top" title={t('generate_password')}>
                                    <ControlOutlined onClick={handleGeneratePassword} />
                                </Tooltip>
                            }
                        />
                    </Form.Item>
                    <Form.Item<UserRequest> name="country_id" label={t('country')} rules={[{ required: true, message: t('common:rule_required') }]}>
                        <Select
                            placeholder={t('country')}
                            options={countries?.data.paginated}
                            fieldNames={{ value: 'id', label: 'title' }}
                            showSearch
                            optionFilterProp={'title'}
                            onChange={onCountryChange}
                        ></Select>
                    </Form.Item>
                    <Form.Item<UserRequest>
                        name="region_id"
                        label={t('region')}
                        rules={[{ required: true, message: t('common:rule_required') }]}
                        shouldUpdate
                    >
                        <Select
                            placeholder={t('region')}
                            options={regions?.data.paginated}
                            fieldNames={{ value: 'id', label: 'title' }}
                            showSearch
                            optionFilterProp={'title'}
                            disabled={!countryValue}
                        ></Select>
                    </Form.Item>
                    <Form.Item<UserRequest> name="address" label={t('address')}>
                        <Input placeholder={t('address')} />
                    </Form.Item>
                </div>
                <Button type="primary" htmlType="submit" loading={isPending}>
                    {t('common:submit')}
                </Button>
                <Button
                    onClick={() => {
                        navigate(ROUTES.USERS);
                    }}
                    htmlType="button"
                    className="ml-2"
                >
                    {t('common:cancel')}
                </Button>
            </Form>
        </Card>
    );
};
