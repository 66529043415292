import { Button, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/features/router';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import { DevicesStatistics, DevicesTable } from '@/features/devices';
import { useRBAC } from '@/features/auth';

export const Devices = () => {
    const { isAdmin } = useRBAC();
    const { t } = useTranslation('devices');
    const navigate = useNavigate();

    return (
        <>
            <Row className="mb-4 flex-col-reverse md:flex-row gap-y-4">
                <Col xs={{ flex: '1 0 100%' }} md={{ flex: '1 0 50%' }}>
                    <DevicesStatistics />
                </Col>
                {isAdmin() && (
                    <Col xs={{ flex: '1 0 100%' }} md={{ flex: '1 0 25%', offset: 6 }}>
                        <Button
                            type="primary"
                            className="text-sm float-left md:float-right"
                            icon={<PlusOutlined />}
                            onClick={() => navigate(ROUTES.DEVICE_ADD)}
                        >
                            {t('add_device')}
                        </Button>
                    </Col>
                )}
            </Row>

            <Row>
                <Col span={24}>
                    <DevicesTable />
                </Col>
            </Row>
        </>
    );
};
