import { ROUTES } from '@/features/router';
import { SettingOutlined } from '@ant-design/icons';
import { Breadcrumb, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AccidentsTable } from '@/features/accidents/components/AccidentsTable';
import { makeDeviceBreadcrumbs } from '@/utils/helper.ts';
import { useGetDevice } from '@/features/devices';

export const Accidents = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();

    const { data: device } = useGetDevice(parseInt(id!));
    const backUrl = (location.state && location.state.prevLocation) || `${ROUTES.DEVICE_EDIT}/${id}`;

    const breadcrumbItems = [
        {
            title: <Link to={ROUTES.DASHBOARD}>{t('breadcrumbs:home')}</Link>,
        },
        {
            title: <Link to={backUrl}>{makeDeviceBreadcrumbs(device?.data)}</Link>,
        },
        {
            title: t('breadcrumbs:accidents'),
        },
    ];

    return (
        <>
            <div className="flex flex-col md:flex-row justify-between mb-4 gap-y-2.5">
                <Breadcrumb items={breadcrumbItems} />

                <Button className="w-max" icon={<SettingOutlined />} onClick={() => navigate(`${ROUTES.DEVICE_NOTIFICATIONS}/${id}`)}>
                    {t('devices:notification_settings')}
                </Button>
            </div>

            <AccidentsTable entity_class="device" id={parseInt(id!)} />
        </>
    );
};
