import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from '@/features/router';
import { Breadcrumb, Flex } from 'antd';
import { OsmoDeviceAdd } from '@/features/osmo_devices/component/OsmoDeviceAdd.tsx';

export const AddOsmoDevice = () => {
    const { t } = useTranslation('breadcrumbs');

    const breadcrumbItems = [
        {
            title: t('home'),
        },
        {
            title: <Link to={`${ROUTES.OSMO_DEVICES}`}>{t('osmo_devices')}</Link>,
        },
        {
            title: t('osmo_devices_add')
        }
    ];

    return (
        <Flex vertical gap={15}>
            <Breadcrumb items={breadcrumbItems} />
            <OsmoDeviceAdd />
        </Flex>
    )
};
