import React from 'react';
import { ExceptionOutlined, LaptopOutlined, LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps } from 'antd';
import { useAuthContext, useLogout, useRBAC } from '@/features/auth';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@/features/router';
import { UserProfileInfo } from '@/features/common/component/UserProfileInfo.tsx';

export const ProfileDropdown: React.FC = () => {
    const { isAdmin } = useRBAC();
    const { mutateAsync } = useLogout();
    const { t } = useTranslation('common');
    const { removeCredentials } = useAuthContext();
    const navigate = useNavigate();

    const logout = async () => {
        await mutateAsync();
        removeCredentials();
        return navigate(ROUTES.AUTH_LOGIN);
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: <UserProfileInfo />,
        },
        {
            type: 'divider'
        },
        isAdmin()
            ? {
                  key: '2',
                  label: <Link to={ROUTES.REGIONS}>{t('common:directory_of_regions')}</Link>,
                  icon: <ExceptionOutlined style={{ fontSize: 16 }} />,
              }
            : null,
        isAdmin()
            ? {
                  key: '3',
                  label: <Link to={ROUTES.DEVICE_MODELS}>{t('common:directory_of_devices')}</Link>,
                  icon: <LaptopOutlined style={{ fontSize: 16 }} />,
              }
            : null,
        {
            key: '4',
            label: <div onClick={logout}>{t('common:logout')}</div>,
            icon: <LogoutOutlined style={{ fontSize: 16 }} />,
        },
    ];

    return (
        <div className="flex justify-end">
            <Dropdown menu={{ items }} placement="bottomRight" className="px-4">
                <a onClick={(e) => e.preventDefault()}>
                    <SettingOutlined style={{ fontSize: 18, color: 'rgba(0, 0, 0, 0.45)' }} />
                </a>
            </Dropdown>
        </div>
    );
};
