import 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import React, { useEffect, useState } from 'react';
import { ChartData, ChartOptions, Point } from 'chart.js';
import { FlowAnalyticsFilter, FlowAnalyticsResponse } from '@/types.ts';
import { ANALYTICS_FLOW_TYPE, EXCLUSIVE_COLORS } from '@/features/analytics/constants';

function generateColorPalette(numColors: number): string[] {
    const colors: string[] = [];
    for (let i = 0; i < numColors; i++) {
        const hue = i * (360 / numColors);
        colors.push(`hsl(${hue}, 70%, 60%)`);
    }
    return colors;
}

function LineDatasets(dateFrom: Date, dateTo: Date, analytics?: FlowAnalyticsResponse, translate: any) {
    const durationInMonths = (dateTo.getFullYear() - dateFrom.getFullYear()) * 12 + dateTo.getMonth() - dateFrom.getMonth();

    const uniqueTypes = Array.from(new Set(analytics?.data?.entries?.map((entry) => entry.analytics_type) || []));
    const colors = generateColorPalette(uniqueTypes.length);

    const datasets = uniqueTypes.map((type, index) => ({
        labels: [],
        volumes: [],
        type,
        color: EXCLUSIVE_COLORS[index] ?? colors[index],
    }));
    const labelsName = {
        [ANALYTICS_FLOW_TYPE.PERMEAT]: translate('consumption'),
        [ANALYTICS_FLOW_TYPE.SERVICE_1]: translate('service') + ' 1',
        [ANALYTICS_FLOW_TYPE.SERVICE_2]: translate('service') + ' 2',
    };

    if (datasets.length === 0) {
        return [];
    }

    if (durationInMonths <= 1) {
        const dateToLocaleString = (date: Date) =>
            date
                .toLocaleDateString('uk', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                })
                .replace(/\//g, '.');
        const inclusiveDateTo = new Date(dateTo);
        inclusiveDateTo.setDate(dateTo.getDate() + 1);

        const labelMap: Record<string, number> = {};
        for (let day = new Date(dateFrom); day < inclusiveDateTo; day.setDate(day.getDate() + 1)) {
            const label = dateToLocaleString(day);
            labelMap[label] = datasets[0].labels.length;
            datasets.forEach((dataset) => {
                dataset.labels.push(label);
                dataset.volumes.push(0);
            });
        }

        analytics?.data?.entries?.forEach(({ flow_date, flow_volume, analytics_type }) => {
            const date = new Date(flow_date);
            if (date >= dateFrom && date < inclusiveDateTo) {
                const label = dateToLocaleString(date);
                const dataset = datasets.find((d) => d.type === analytics_type);
                if (dataset) {
                    const dayIndex = labelMap[label];
                    if (dayIndex !== undefined) {
                        dataset.volumes[dayIndex] += parseFloat(flow_volume);
                    }
                }
            }
        });
    } else {
        const dateToLocaleString = (date: Date) =>
            date
                .toLocaleDateString('uk', {
                    month: '2-digit',
                    year: 'numeric',
                })
                .replace(/\//g, '.');
        const startYear = dateFrom.getFullYear();
        const endYear = dateTo.getFullYear();

        const labelMap: Record<string, number> = {};
        for (let i = 0; i <= (endYear - startYear) * 12 + dateTo.getMonth() - dateFrom.getMonth(); i++) {
            const monthDate = new Date(startYear + Math.floor((dateFrom.getMonth() + i) / 12), (dateFrom.getMonth() + i) % 12, 1);
            const label = dateToLocaleString(monthDate);
            labelMap[label] = datasets[0].labels.length;
            datasets.forEach((dataset) => {
                dataset.labels.push(label);
                dataset.volumes.push(0);
            });
        }

        analytics?.data?.entries?.forEach(({ flow_date, flow_volume, analytics_type }) => {
            const date = new Date(flow_date);
            const label = dateToLocaleString(new Date(date.getFullYear(), date.getMonth(), 1));
            const dataset = datasets.find((d) => d.type === analytics_type);
            if (dataset) {
                const monthIndex = labelMap[label];
                if (monthIndex !== undefined) {
                    dataset.volumes[monthIndex] += parseFloat(flow_volume);
                }
            }
        });
    }
    return datasets.map((dataset) => ({
        labels: dataset.labels,
        data: dataset.volumes,
        borderColor: dataset.color,
        label: `${labelsName[dataset.type]}`,
        hidden: dataset.type !== 0,
        fill: true,
    }));
}

type DevicesFilterProps = {
    analytics?: FlowAnalyticsResponse;
    filter: FlowAnalyticsFilter;
    translate: any;
};

export const AnalyticsLineChart: React.FC<DevicesFilterProps> = ({ analytics, filter, translate }) => {
    const [data, setData] = useState<ChartData<'line', (number | Point | null)[], unknown>>();

    const options: ChartOptions<'line' | any> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'bottom',
                display: false
            },
            tooltip: {
                backgroundColor: '#fff',
                borderColor: '#ffffff',
                borderWidth: 1,
                titleColor: '#000',
                titleFont: {
                    size: 16,
                    weight: 'bold',
                },
                bodyColor: '#000',
                bodyFont: {
                    size: 14,
                },
                callbacks: {
                    title: function (context) {
                        return context[0].label;
                    },
                },
                displayColors: false,
                boxPadding: 8,
                padding: 12,
                xAlign: 'left',
                yAlign: 'center',
                caretPadding: 10,
                cornerRadius: 8,
            },
        },
        elements: {
            point: {
                radius: 4,
                hoverRadius: 6,
                backgroundColor: '#ffffff',
                hoverBackgroundColor: '#ffffff',
                borderColor: '#0050B3',
                borderWidth: 2,
            },
            line: {
                tension: 0.5,
                borderWidth: 2,
                borderColor: '#0050B3',
                fill: 'start',
                backgroundColor: 'rgba(0, 0, 0, 0)',
            },
        },
    };

    useEffect(() => {
        const dateFrom = new Date(filter.date_from || '');
        const dateTo = new Date(filter.date_to || '');
        const datasets = LineDatasets(dateFrom, dateTo, analytics, translate);

        const chartData: ChartData<'line'> = {
            labels: datasets[0]?.labels || '',
            datasets:
                datasets.length > 0
                    ? datasets.map((dataset) => ({
                          data: dataset.data,
                          borderColor: dataset.borderColor,
                          label: dataset.label,
                          hidden: dataset.hidden,
                          fill: true,
                      }))
                    : [],
        };
        setData(chartData);
    }, [analytics, filter]);

    return <>{data && <Line data={data} height={400} options={options} />}</>;
};
