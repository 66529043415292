import { createBrowserRouter, Navigate, redirect, RouterProvider } from 'react-router-dom';
import { AuthLayout, DefaultLayout } from '@/layouts';
import { Users } from '@/pages/users/Users.tsx';
import { Login } from '@/pages/Login.tsx';
import { PrivateRoute } from '@/features/router/context/PrivateRoute.tsx';
import { ROUTES } from '@/features/router';
import { AddUser } from '@/pages/users/AddUser.tsx';
import { EditUser } from '@/pages/users/EditUser.tsx';
import { Countries } from '@/pages/countries/Countries.tsx';
import { EditCountry } from '@/pages/countries/EditCountry.tsx';
import { AddCountry } from '@/pages/countries/AddCountry.tsx';
import { Regions } from '@/pages/regions/Regions.tsx';
import { EditRegion } from '@/pages/regions/EditRegion.tsx';
import { AddRegion } from '@/pages/regions/AddRegion.tsx';
import { DeviceModels } from '@/pages/device-models/DeviceModels.tsx';
import { AddDeviceModel } from '@/pages/device-models/AddDeviceModel.tsx';
import { EditDeviceModel } from '@/pages/device-models/EditDeviceModel.tsx';
import { Devices } from '@/pages/devices/Devices.tsx';
import { EditDevice } from '@/pages/devices/EditDevice.tsx';
import { AddDevice } from '@/pages/devices/AddDevice.tsx';
import { Accidents } from '@/pages/devices/accidents/Accidents.tsx';
import { NotificationSettings } from '@/pages/devices/NotificationSettings.tsx';
import { ViewAnalytics } from '@/pages/analytics/View.tsx';
import { Device } from '@/pages/devices/Device.tsx';
import { OsmoDevices } from '@/pages/osmo_devices/OsmoDevices.tsx';
import { AddOsmoDevice } from '@/pages/osmo_devices/AddOsmoDevice.tsx';
import { EditOsmoDevice } from '@/pages/osmo_devices/EditOsmoDevice.tsx';
import { OsmoNotificationSettings } from '@/pages/osmo_devices/OsmoNotificationSettings.tsx';
import { OsmoDeviceAccidents } from '@/pages/osmo_devices/OsmoDeviceAccidents.tsx';
import React, { useMemo } from 'react';
import { blockArduinoPredicate } from '@/features/common/helpers.ts';
import { useRBAC } from '@/features/auth';
import { ClientMobileWrapper } from '@/features/client-mobile/components/ClientMobileWrapper.tsx';
import { ClientDevices } from '@/pages/client-mobile/ClientDevices.tsx';
import { ClientOsmoDevice } from '@/pages/client-mobile/ClientOsmoDevice.tsx';
import { ClientOsmoProDevice } from '@/pages/client-mobile/ClientOsmoProDevice.tsx';

type BlockedRouteProps = {
    predicate?: () => boolean;
    isBlocked?: boolean;
    children: React.ReactNode;
};

const BlockedRoute: React.FC<BlockedRouteProps> = ({ predicate, isBlocked, children }) => {
    if ((typeof predicate != 'undefined' && predicate()) || (typeof isBlocked != 'undefined' && isBlocked)) {
        return <Navigate to={ROUTES.DASHBOARD} replace />;
    }

    return children;
};

export const Router = () => {
    const { isClient, isAdmin } = useRBAC();

    const arduinoBlocked = blockArduinoPredicate();
    const isUserClient = isClient();
    const isUserAdmin = isAdmin();

    const router = useMemo(
        () =>
            createBrowserRouter([
                {
                    path: '/',
                    element: (
                        <PrivateRoute>
                            <DefaultLayout />
                        </PrivateRoute>
                    ),
                    children: [
                        {
                            path: ROUTES.DASHBOARD,
                            element: (
                                <ClientMobileWrapper defaultChild={<Devices />}>
                                    <ClientDevices />
                                </ClientMobileWrapper>
                            ),
                        },
                        {
                            path: ROUTES.USERS,
                            element: (
                                <BlockedRoute isBlocked={isUserClient}>
                                    <Users />
                                </BlockedRoute>
                            ),
                        },
                        {
                            path: `${ROUTES.USER_EDIT}/:id`,
                            element: (
                                <BlockedRoute isBlocked={isUserClient}>
                                    <EditUser />
                                </BlockedRoute>
                            ),
                        },
                        {
                            path: ROUTES.USER_ADD,
                            element: (
                                <BlockedRoute isBlocked={isUserClient}>
                                    <AddUser />
                                </BlockedRoute>
                            ),
                        },
                        {
                            path: ROUTES.COUNTRIES,
                            element: (
                                <BlockedRoute isBlocked={!isUserAdmin}>
                                    <Countries />
                                </BlockedRoute>
                            ),
                        },
                        {
                            path: `${ROUTES.COUNTRY_EDIT}/:id`,
                            element: (
                                <BlockedRoute isBlocked={!isUserAdmin}>
                                    <EditCountry />
                                </BlockedRoute>
                            ),
                        },
                        {
                            path: ROUTES.COUNTRY_ADD,
                            element: (
                                <BlockedRoute isBlocked={!isUserAdmin}>
                                    <AddCountry />
                                </BlockedRoute>
                            ),
                        },
                        {
                            path: ROUTES.REGIONS,
                            element: (
                                <BlockedRoute isBlocked={!isUserAdmin}>
                                    <Regions />
                                </BlockedRoute>
                            ),
                        },
                        {
                            path: `${ROUTES.REGION_EDIT}/:id`,
                            element: (
                                <BlockedRoute isBlocked={!isUserAdmin}>
                                    <EditRegion />
                                </BlockedRoute>
                            ),
                        },
                        {
                            path: ROUTES.REGION_ADD,
                            element: (
                                <BlockedRoute isBlocked={!isUserAdmin}>
                                    <AddRegion />
                                </BlockedRoute>
                            ),
                        },
                        {
                            path: ROUTES.DEVICE_MODELS,
                            element: (
                                <BlockedRoute isBlocked={!isUserAdmin}>
                                    <DeviceModels />
                                </BlockedRoute>
                            ),
                        },
                        {
                            path: `${ROUTES.DEVICE_MODEL_EDIT}/:id`,
                            element: (
                                <BlockedRoute isBlocked={!isUserAdmin}>
                                    <EditDeviceModel />
                                </BlockedRoute>
                            ),
                        },
                        {
                            path: ROUTES.DEVICE_MODEL_ADD,
                            element: (
                                <BlockedRoute isBlocked={!isUserAdmin}>
                                    <AddDeviceModel />
                                </BlockedRoute>
                            ),
                        },
                        {
                            path: ROUTES.DEVICES,
                            element: <Devices />,
                        },
                        {
                            path: `${ROUTES.DEVICE_EDIT}/:id`,
                            element: (
                                <BlockedRoute isBlocked={isUserClient}>
                                    <EditDevice />
                                </BlockedRoute>
                            ),
                        },
                        {
                            path: ROUTES.DEVICE_ADD,
                            element: (
                                <BlockedRoute isBlocked={!isUserAdmin}>
                                    <AddDevice />
                                </BlockedRoute>
                            ),
                        },
                        {
                            path: `${ROUTES.DEVICE_ACCIDENTS}/:id`,
                            element: <Accidents />,
                        },
                        {
                            path: `${ROUTES.DEVICE_NOTIFICATIONS}/:id`,
                            element: (
                                <BlockedRoute isBlocked={isUserClient}>
                                    <NotificationSettings />
                                </BlockedRoute>
                            ),
                        },
                        {
                            path: `${ROUTES.ANALYTICS_VIEW}/:id`,
                            element: <ViewAnalytics />,
                        },
                        {
                            path: `${ROUTES.DEVICE_IFRAME}/:id`,
                            element: <Device />,
                        },
                        {
                            path: ROUTES.OSMO_DEVICES,
                            element: (
                                <BlockedRoute isBlocked={arduinoBlocked}>
                                    <OsmoDevices />
                                </BlockedRoute>
                            ),
                        },
                        {
                            path: ROUTES.OSMO_DEVICES_ADD,
                            element: (
                                <BlockedRoute isBlocked={arduinoBlocked || !isUserAdmin}>
                                    <AddOsmoDevice />
                                </BlockedRoute>
                            ),
                        },
                        {
                            path: `${ROUTES.OSMO_DEVICES_EDIT}/:id`,
                            element: (
                                <BlockedRoute isBlocked={arduinoBlocked || isUserClient}>
                                    <EditOsmoDevice />
                                </BlockedRoute>
                            ),
                        },
                        {
                            path: `${ROUTES.OSMO_DEVICES_NOTIFICATIONS}/:id`,
                            element: (
                                <BlockedRoute isBlocked={arduinoBlocked || isUserClient}>
                                    <OsmoNotificationSettings />
                                </BlockedRoute>
                            ),
                        },
                        {
                            path: `${ROUTES.OSMO_DEVICES_ACCIDENTS}/:id`,
                            element: (
                                <BlockedRoute isBlocked={arduinoBlocked}>
                                    <OsmoDeviceAccidents />
                                </BlockedRoute>
                            ),
                        },
                        {
                            path: `${ROUTES.OSMO_DEVICES_VIEW}/:id`,
                            element: <ClientOsmoDevice />,
                        },
                        {
                            path: `${ROUTES.DEVICES_VIEW}/:id`,
                            element: <ClientOsmoProDevice />,
                        },
                    ],
                },
                {
                    path: '/auth',
                    element: <AuthLayout />,
                    children: [
                        {
                            index: true,
                            loader: () => redirect('/auth/login'),
                        },
                        {
                            path: 'login',
                            element: <Login />,
                        },
                    ],
                },
            ]),
        [arduinoBlocked, isUserClient, isUserAdmin]
    );

    return <RouterProvider router={router} />;
};
