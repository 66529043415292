import React, { useCallback, useMemo } from 'react';
import type { MenuProps } from 'antd';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '@/features/router';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from './../context';
import { ROLES } from './../constants.ts';
import { blockArduinoPredicate } from '@/features/common/helpers.ts';

type MenuItem = Required<MenuProps>['items'][number];

export const useRBAC = () => {
    const { t } = useTranslation('common');
    const { currentUser } = useAuthContext();

    const getItem = (label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], disabled?: boolean) => {
        if (disabled) {
            return null;
        }

        return {
            key,
            icon,
            children,
            label,
        } as MenuItem;
    };

    const isAdmin = useCallback(() => {
        return currentUser?.role === ROLES.ADMINISTRATOR;
    }, [currentUser]);

    const isDiller = useCallback(() => {
        return currentUser?.role === ROLES.DILLER;
    }, [currentUser]);

    const isClient = useCallback(() => {
        return currentUser?.role === ROLES.USER;
    }, [currentUser]);

    const isCurrentUser = useCallback(
        (id: number) => {
            return id === currentUser?.id;
        },
        [currentUser]
    );

    const isArduinoBlocked = blockArduinoPredicate();

    const menuDependingOnRole: MenuItem[] = useMemo(() => {
        return [
            getItem(<NavLink to={ROUTES.DASHBOARD}>{t('common:osmo_pro')}</NavLink>, ROUTES.DASHBOARD),
            getItem(
                <NavLink to={ROUTES.OSMO_DEVICES}>{t('common:osmo_devices')}</NavLink>,
                ROUTES.OSMO_DEVICES,
                undefined,
                undefined,
                isArduinoBlocked
            ),
            getItem(<NavLink to={ROUTES.USERS}>{t('common:users')}</NavLink>, ROUTES.USERS, undefined, undefined, isClient()),
        ];
    }, [t, isArduinoBlocked]);

    return {
        isAdmin,
        isDiller,
        isClient,
        isCurrentUser,
        menuDependingOnRole,
    };
};
