import React from 'react';
import { AdaptiveContext } from '@/features/adaptive/context/Context.ts';
import { useMediaQuery } from '@mui/material';

interface AdaptiveContextProviderProps extends React.PropsWithChildren {}

export const AdaptiveContextProvider: React.FC<AdaptiveContextProviderProps> = ({ children }) => {
    const breakpointMap = {
        isMediaSm: useMediaQuery('(min-width: 576px)'),
        isMediaMd: useMediaQuery('(min-width: 768px)'),
        isMediaLg: useMediaQuery('(min-width: 992px)'),
        isMediaXl: useMediaQuery('(min-width: 1200px)'),
        isMediaXxl: useMediaQuery('(min-width: 1400px)'),
        isDesktopOrLaptop: useMediaQuery("(min-width: 1024px)"),
        isTabletOrMobile: useMediaQuery("(max-width: 768px)")
    };

    return <AdaptiveContext.Provider value={breakpointMap}>{children}</AdaptiveContext.Provider>;
};
