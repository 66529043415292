import { Card, Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { RegionsTableFilter } from '@/types.ts';
import { useCountries } from '@/features/countries';

type RegionsFilterProps = {
    handleSearch: (values?: RegionsTableFilter) => void;
};

export const RegionsFilter: React.FC<RegionsFilterProps> = ({ handleSearch }) => {
    const { data: countries } = useCountries(1, 100);
    const { t } = useTranslation(['regions']);

    return (
        <Card className={'mb-4'}>
            <Form
                onValuesChange={(_, values) => {
                    handleSearch(values);
                }}
                labelCol={{ xs: { flex: '1 0 auto' }, md: { flex: '1 0 auto' } }}
                wrapperCol={{ xs: { flex: '1 0 auto' }, md: { span: 4 } }}
            >
                <Form.Item<RegionsTableFilter> name="country_id" label={t('regions:country')} className={'mb-0'}>
                    <Select
                        placeholder={t('regions:select_country')}
                        options={countries?.data.paginated}
                        fieldNames={{ value: 'id', label: 'title' }}
                        showSearch
                        allowClear={true}
                        optionFilterProp={'title'}
                    ></Select>
                </Form.Item>
            </Form>
        </Card>
    );
};
