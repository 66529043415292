import common from './common.json';
import breadcrumbs from './breadcrumbs.json';
import auth from './auth.json';
import users from './users.json';
import countries from './countries.json';
import regions from './regions.json';
import device_models from './device_models.json';
import devices from './devices.json';
import accidents from './accidents.json';
import accidents_text from './accidents_text.json';
import accidents_status from './accidents_status.json';
import osmo_devices from './osmo_devices.json';

export const uk = {
    accidents,
    common,
    breadcrumbs,
    auth,
    users,
    countries,
    regions,
    device_models,
    devices,
    accidents_text,
    accidents_status,
    osmo_devices
};
