import {Providers} from '@/providers.tsx';
import {RouterProvider} from '@/features/router';

function App() {

    return (
        <Providers>
            <RouterProvider/>
        </Providers>
    );
}

export default App;
