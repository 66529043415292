import React, { useEffect } from 'react';
import { Form, Input, Button, Card, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { RegionRequest } from '@/types.ts';
import { useNavigate, useParams } from 'react-router-dom';
import { useMessageApiContext } from '@/features/message-api/context';
import { ROUTES } from '@/features/router';
import { useGetRegion, useUpdateRegion } from '@/features/regions';
import { useCountries } from '@/features/countries';

export const RegionEditForm: React.FC = () => {
    const { t } = useTranslation(['regions', 'common']);
    const { id } = useParams();
    const { data, isLoading, isSuccess } = useGetRegion(parseInt(id!));
    const { mutateAsync, isPending } = useUpdateRegion(parseInt(id!));
    const { data: countries } = useCountries(1);
    const { messageApi } = useMessageApiContext();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    useEffect(() => {
        if (isSuccess) {
            form.setFieldsValue({
                title: data.data.title,
                country_id: data.data.country.id,
            });
        }
    }, [isSuccess, data, form]);

    const onSubmit = async (values: RegionRequest) => {
        try {
            await mutateAsync({
                title: values.title,
                country_id: values.country_id,
            });
            messageApi.open({
                type: 'success',
                content: t('common:message_success'),
            });
            navigate(ROUTES.REGIONS);
        } catch (e) {
            messageApi.open({
                type: 'error',
                content: t('common:message_error'),
            });
        }
    };

    return (
        <Card title={t('regions:edit_region')} loading={isLoading}>
            <Form layout="vertical" form={form} onFinish={onSubmit} requiredMark={false}>
                <div className="grid grid-flow-row md:grid-cols-2 gap-x-4">
                    <Form.Item<RegionRequest>
                        name="country_id"
                        label={t('regions:country')}
                        rules={[{ required: true, message: t('common:rule_required') }]}
                    >
                        <Select
                            placeholder={t('regions:country')}
                            options={countries?.data.paginated}
                            fieldNames={{ value: 'id', label: 'title' }}
                            showSearch
                            optionFilterProp={'title'}
                        ></Select>
                    </Form.Item>
                    <Form.Item<RegionRequest>
                        name="title"
                        label={t('regions:region')}
                        rules={[{ required: true, message: t('common:rule_required') }]}
                    >
                        <Input placeholder={t('regions:region')} />
                    </Form.Item>
                </div>

                <Button type="primary" htmlType="submit" loading={isPending}>
                    {t('common:submit')}
                </Button>
                <Button
                    onClick={() => {
                        navigate(ROUTES.REGIONS);
                    }}
                    htmlType="button"
                    className="ml-2"
                >
                    {t('common:cancel')}
                </Button>
            </Form>
        </Card>
    );
};
