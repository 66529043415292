import React from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { UserLocaleProvider } from './context';

export const TranslationProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    return (
        <I18nextProvider i18n={i18n}>
            <UserLocaleProvider>{children}</UserLocaleProvider>
        </I18nextProvider>
    );
};
