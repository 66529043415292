import React from 'react';
import { useParams } from 'react-router-dom';
import { OsmoDeviceForm } from '@/features/osmo_devices/component/OsmoDeviceForm.tsx';
import { useGetOsmoDevice, useUpdateOsmoDevice } from '@/features/osmo_devices/hooks/api.ts';

export const OsmoDeviceEdit: React.FC = () => {
    const { id } = useParams();
    const { data, isLoading, isSuccess } = useGetOsmoDevice(parseInt(id!));
    const { mutateAsync } = useUpdateOsmoDevice(parseInt(id!));

    return <OsmoDeviceForm data={data} isSuccess={isSuccess} isLoading={isLoading} mutateFn={mutateAsync} />;
};
