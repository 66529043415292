import { createContext, useContext } from 'react';
import { User } from '@/types.ts';

interface AuthContextProps {
    isAuth: boolean;
    setCredentials: () => void;
    removeCredentials: () => void;
    currentUser: User | null;
    setActiveUser: (user: User) => void;
}

export const AuthContext = createContext<AuthContextProps>({
    isAuth: false,
    setCredentials: () => {},
    removeCredentials: () => {},
    currentUser: null,
    setActiveUser: () => {},
});

export const useAuthContext = () => useContext(AuthContext);
