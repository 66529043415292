import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { StatusBadgeStatus } from '@/types.ts';

export type StatusBadgeProps = {
    status: StatusBadgeStatus;
};

export const StatusBadge: React.FC<StatusBadgeProps> = memo(function StatusBadge({ status }) {
    const { t } = useTranslation('common');

    const colorTheme = {
        text: `text-status-${status}-text`,
        bg: `bg-status-${status}-bg`,
        border: `border-status-${status}-border`,
    };

    return (
        <div className={`flex justify-center items-center ${colorTheme.bg} ${colorTheme.border} border rounded py-px px-[8px]`}>
            <span className={colorTheme.text}>{t(status)}</span>
        </div>
    );
});
