import { API_ROUTES } from '@/api/routes.ts';
import { useAxios } from '@/features/axios';
import { DeviceAccidentFilter, DeviceAccidentSortBy, DeviceAccidentTableCollection, DeviceEntityClass } from '@/types';
import { useQuery } from '@tanstack/react-query';

export const useDeviceEvents = (
    id: number,
    page: number,
    language: string,
    entity_class: DeviceEntityClass,
    perPage?: number,
    filter?: DeviceAccidentFilter,
    sortBy?: DeviceAccidentSortBy
) => {
    const axios = useAxios();

    return useQuery({
        queryKey: [API_ROUTES.DEVICE_EVENTS.KEY, id, language, page, perPage, sortBy, entity_class],
        queryFn: async () => {
            let params = {
                filterBy: {} as DeviceAccidentFilter,
                sortBy: {} as DeviceAccidentSortBy,
                page: page,
                per_page: perPage,
                entity_class: entity_class,
            };

            if (filter) {
                params.filterBy = filter;
            }

            if (sortBy) {
                params.sortBy = sortBy;
            }

            return (await axios.get<DeviceAccidentTableCollection>(`${API_ROUTES.DEVICE_EVENTS.URL}/${id}`, { params })).data;
        },
    });
};

export const useGetDeviceEventsHelpers = (id: number, language: string, entity_class: DeviceEntityClass) => {
    const axios = useAxios();

    return useQuery({
        queryKey: [API_ROUTES.DEVICE_HELPERS.KEY, id, language, entity_class],
        queryFn: async () => {
            const params = {
                entity_class: entity_class,
            };

            return await axios.get(`${API_ROUTES.DEVICE_HELPERS.URL}/${id}`, { params });
        },
    });
};
